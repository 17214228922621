import { createTheme } from '@material-ui/core/styles';
import { NovozymesTheme } from '@novozymes-digital/components';

const frontiaTheme = createTheme({
  ...NovozymesTheme,
  palette: {
    ...NovozymesTheme.palette,
    background: {
      default: '#f6f7f5',
    },
    action: {
      ...NovozymesTheme.palette.action,
      hover: '#90a900',
      disabled: '#ffffff',
      disabledBackground: '#848c84',
    },
    primary: {
      ...NovozymesTheme.palette.primary,
      main: '#90a900',
      dark: '#c5da00',
      light: '#848c84',
    },
    secondary: {
      ...NovozymesTheme.palette.secondary,
      main: '#0f3e49',
    },
  },
});

export default frontiaTheme;
