/* eslint-disable max-len */

import React, { ReactElement, useState, useEffect, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
  IconButton,
  Theme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import CtaButton from 'components/CtaButton';
import CountryOptions from 'staticData/CountryOptions';
import { AppState, YesNo } from 'appState/AppStateTypes';
import { Region } from 'staticData/Region';
import { getClientId, pardotFormHandler } from '@novozymes-digital/utils';
import { useTranslation, Trans } from 'react-i18next';

type ContactFormDialogProps = {
  show: boolean;
  onClose(): void;
  appState: AppState;
  dialogTitle?: string;
  dialogIntro?: string;
};

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: {
    width: '100%',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: theme.spacing(4),
  },
}));

const PARDOT_FORM_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-06-16/c687n';

export default function ContactFormDialog({
  show,
  onClose,
  appState,
  dialogTitle,
  dialogIntro,
  ...props
}: ContactFormDialogProps): ReactElement {
  const [open, setOpen] = useState(show);
  const formRef = useRef<HTMLFormElement>(null);

  const pardotData = {
    region: Region[appState.inputFields.region.value],
    daysOperating: appState.inputFields.daysOperating.value,
    daysFullCapacity: appState.inputFields.daysFullCapacity.value,
    totalCapcity: appState.inputFields.totalCapcity.value,
    produceEthanol: YesNo[appState.inputFields.produceEthanol.value],
    feedDryer: YesNo[appState.inputFields.feedDryer.value],
    sellSteepLiqour: YesNo[appState.inputFields.sellSteepLiqour.value],
    excessCornSteepLiqour: appState.inputFields.excessCornSteepLiqour.value,
    priceInput: {
      Corn: appState.priceInputFields.Corn.value,
      CGF: appState.priceInputFields.CGF.value,
      CSL: appState.priceInputFields.CSL.value,
      CGM: appState.priceInputFields.CGM.value,
      Germ: appState.priceInputFields.Germ.value,
      Ethanol: appState.priceInputFields.Ethanol.value,
      NativeStarch: appState.priceInputFields.NativeStarch.value,
    },
  };

  // eslint-disable-next-line no-undef
  const sourceUrl = window.location.href;
  const gaId = getClientId();
  const classes = useStyles();

  const handleClose = useCallback((): void => {
    setOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();

      if (formRef.current == null) {
        return;
      }
      pardotFormHandler(PARDOT_FORM_ENDPOINT, formRef.current, handleClose);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formRef]
  );

  const { t } = useTranslation('contactForm');

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle disableTypography={false} id="alert-dialog-title">
        <Box p={2}>
          <Typography variant="h2" style={{ fontSize: '1.7rem' }}>
            <span dangerouslySetInnerHTML={{ __html: `${dialogTitle || t('heading')}` }}></span>
          </Typography>
          <Typography variant="body1">
            <span dangerouslySetInnerHTML={{ __html: `${dialogIntro || t('intro')}` }}></span>
          </Typography>
        </Box>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent className={classes.scroll}>
        <form onSubmit={onSubmit} ref={formRef}>
          <Grid container>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  required
                  name="first_name"
                  label={t('firstName')}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  required
                  name="last_name"
                  label={t('lastName')}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  required
                  name="email"
                  label={t('eMail')}
                  type="mail"
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  select
                  required
                  name="job_title"
                  label={t('jobFunction')}
                >
                  <MenuItem value="CEO/President/Owner">{t('CEO/President/Owner')}</MenuItem>
                  <MenuItem value="Educational">{t('educational')}</MenuItem>
                  <MenuItem value="Finance">{t('finance')}</MenuItem>
                  <MenuItem value="Marketing">{t('marketing')}</MenuItem>
                  <MenuItem value="Procurement">{t('procurement')}</MenuItem>
                  <MenuItem value="Product Developer">{t('productDeveloper')}</MenuItem>
                  <MenuItem value="Research & Development">{t('rnd')}</MenuItem>
                  <MenuItem value="Other">{t('other')}</MenuItem>
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  required
                  name="company"
                  label={t('company')}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  select
                  required
                  name="location"
                  label={t('location')}
                >
                  {CountryOptions.map((option) => (
                    <MenuItem value={option.value} key={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <TextField
                  className={classes.fullWidth}
                  variant="outlined"
                  multiline
                  rowsMax="10"
                  name="comments"
                  label={t('comments')}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <FormControlLabel
                  control={<Checkbox required value="True" name="i_accept_terms" />}
                  label={
                    <Typography variant="caption">
                      <Trans
                        ns="contactForm"
                        i18nKey="agreeTermsConditions"
                        components={{
                          terms: <a target="_blank" rel="noopener noreferrer" href="/terms" />,
                          privacy: (
                            <a target="_blank" rel="noopener noreferrer" href="https://www.novozymes.com/en/privacy" />
                          ),
                        }}
                      />
                    </Typography>
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box m={2}>
                <FormControlLabel
                  control={<Checkbox value="True" name="i_opt_in" />}
                  label={<Typography variant="caption">{t('occasionalUpdates')}</Typography>}
                />
              </Box>
            </Grid>
            <input type="hidden" name="subject" value="Other" />
            <input type="hidden" name="area_of_business" value="Food and Beverages" />
            <input type="hidden" name="industry_segment" value="Starch" />
            <input type="hidden" name="product" value="Frontia" />
            <input type="hidden" name="pardot_source" value={sourceUrl} />
            <input type="hidden" name="ga_client_id" value={gaId} />
            <input type="hidden" name="pardot_calculator_values" value={JSON.stringify(pardotData)} />
            <Grid item xs={12} className={classes.textAlignCenter}>
              <CtaButton
                type="submit"
                id="result_submitForm"
                value="submit"
                variant="contained"
                color="primary"
                data-e2e="ResultPage-SubmitForm"
              >
                {t('submit')}
              </CtaButton>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
}
