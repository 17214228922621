import { Box, Fade, Grid, makeStyles, Paper, Typography, Theme } from '@material-ui/core';
import { AppState } from 'appState/AppStateTypes';
import { CalculateOutput, SustainabilityOutput } from 'calculation/CalculationTypes';
import DownloadDialog from 'components/dialogs/DownloadDialog';
import ContactFormDialog from 'components/dialogs/ContactFormDialog';
import Pdf from 'components/Pdf';
import React, { ReactElement, useCallback, useState } from 'react';
import CookieHelper from 'utils/CookieHelper';
import useIsDesktop, { useIsSmall } from 'utils/MediaQueryHelper';
import { formatNumberString } from 'utils/NumberHelper';
import { useTranslation } from 'react-i18next';
import typography from '../../theme/typography';
import { Button, Loading } from '@novozymes-digital/components';
import { Region } from 'staticData/Region';
import TypographyWithTooltip from 'components/TypographyWithTooltip';
import { cloneDeep } from 'lodash';

type BenefitDisplayProps = {
  grossbenefit: CalculateOutput['grossbenefit'];
  higherSales: CalculateOutput['higherSales'];
  energySavings: CalculateOutput['energySavings'];
  starchCost: CalculateOutput['starchCost'];
  flexibility: CalculateOutput['flexibility'];
  payBack: CalculateOutput['payBack'];
  sustainability: SustainabilityOutput;
  formatLabel(value: number, decimals?: number): string;
  updateSeveralValues: (newValues: { attr: string; value: number }[]) => void;
  loading: boolean;
  appState: AppState;

  onPriceClick: () => void;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
};

const useStyles = makeStyles((theme: Theme) => ({
  ...typography,
  grossBenefitsPaper: {
    position: 'relative',
    borderRadius: '16px',
    minHeight: '34.688rem',
    [theme.breakpoints.down(960)]: {
      borderRadius: '4px',
    },
  },
  sustainabilityPaper: {
    position: 'relative',
    borderRadius: '16px',
    [theme.breakpoints.down(960)]: {
      borderRadius: '4px',
    },
  },
  centerContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(2),
  },
  centerButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingBottom: '3.438rem',
  },
  centeredHeading: {
    width: '100%',
    textAlign: 'center',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  textBox: {
    padding: theme.spacing(4),
    alignSelf: 'flex-start',
  },
  emptyResultsContainer: {
    padding: '40px 88px',
    minHeight: '34.688rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down(960)]: {
      padding: '16px 24px',
      minHeight: '48rem',
    },
  },
  emptyResultsPaper: {
    position: 'relative',
    [theme.breakpoints.down(1280)]: {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    },
  },
  emptyResultsBenefits: {
    marginTop: '2.438rem',
    marginBottom: '2rem',
    [theme.breakpoints.down(960)]: {
      marginBottom: '1rem',
    },
  },
  grossBenefit: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
  },
  valueDriver: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(2),
    textAlign: 'left',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  download: {
    position: 'absolute',
    right: '16px',
    top: '8px',
    zIndex: 2,
    cursor: 'pointer',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  valueDriverText: {
    textAlign: 'center',
    minHeight: '40px',
  },
  img: {
    width: '80px',
    filter: 'invert(0.2)',
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
  },
  imgEarth: {
    width: '85px',
    filter: 'invert(0.2)',
    [theme.breakpoints.down('md')]: {
      width: '65px',
    },
  },
  icon: {
    width: '105px',
    filter: 'invert(0.2)',
    [theme.breakpoints.down('md')]: {
      width: '88px',
    },
  },
  grossBenefitsContainer: {
    backgroundImage: `url('/img/grossBenefits.png')`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 90%',
    width: `calc(100vw + 48px)`,
    borderRadius: '16px 0px 0px 16px',
    height: '34.688rem',
    [theme.breakpoints.down(960)]: {
      borderRadius: '4px 4px 0px 0px',
      height: '12rem',
      backgroundPosition: '50% 65%',
    },
  },
  sustainabilityContainer: {
    backgroundImage: `url('/img/improvedSustainability.png')`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: `calc(100vw + 48px)`,
    height: '14.813rem',
    borderRadius: '16px 0px 0px 16px',
    [theme.breakpoints.down(960)]: {
      borderRadius: '4px 4px 0px 0px',
      height: '7.5rem',
    },
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    alignItems: 'flex-start',
  },
  buttonsContainer: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    paddingRight: '8px',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexWrap: 'wrap-reverse',
      paddingRight: '0px',
    },
  },
  bookingButton: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: '11.5rem',
    },
    [theme.breakpoints.down(473)]: {
      marginTop: '0.625rem',
    },
  },
  imgContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '24px',
  },
  sustainabilityLabel: {
    ...typography.h4,
    textAlign: 'center',
    paddingTop: 16,
    width: '80%',
    [theme.breakpoints.down('xs')]: {
      ...typography.p3,
      width: '100%',
    },
  },
}));

const BenefitDisplay = ({
  grossbenefit,
  higherSales,
  energySavings,
  starchCost,
  flexibility,
  payBack,
  formatLabel,
  loading,
  appState,
  sustainability,
  onPriceClick,
  setOpenDrawer,
}: BenefitDisplayProps): ReactElement => {
  const classes = useStyles();
  const [showDownload, setShowDownload] = useState(false);

  const [generatePdf, setGeneratePdf] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const isSmall = useIsSmall();
  const isDesktop = useIsDesktop();
  const cloneSustainability = cloneDeep(sustainability);
  const appstateClone = cloneDeep(appState);
  appstateClone.priceInputFields.Ethanol.hidden = true;
  const onCloseContact = useCallback(() => setShowContact(false), []);
  const { t } = useTranslation('benefitDisplay');

  const assemblePDF = useCallback(async () => {
    setGeneratePdf(true);
  }, []);

  const onSubmitDownload = useCallback(async (cancelled: boolean): Promise<void> => {
    setShowDownload(false);
    if (cancelled) {
      return;
    }
    assemblePDF();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDownload = useCallback(async () => {
    if (CookieHelper.getInstance().get('user')) {
      assemblePDF();
    } else {
      setShowDownload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickContact = useCallback(() => setShowContact(true), []);
  const setLCAValues = (cloneSust: SustainabilityOutput) => {
    cloneSust.starchProducedOutput.avoidConventionalCornMilling = -12.0;
    cloneSust.starchProducedOutput.steamSavedFiberDrying = -9.4;
    cloneSust.starchProducedOutput.frontiaJadeDosage = -0.7;
    cloneSust.starchProducedOutput.inducedAnimalFeedEnergy = 8.9;
    cloneSust.starchProducedOutput.total = -11.9;
  };
  setLCAValues(cloneSustainability);

  if (loading) {
    return (
      <>
        <Box mt="32px" mb={isDesktop ? 4 : 0}>
          <Typography
            variant="h4"
            align={isDesktop ? 'left' : 'center'}
            className={isSmall ? classes.hb6 : classes.hb4}
          >
            <span dangerouslySetInnerHTML={{ __html: `${t('title')}` }}></span>
          </Typography>
        </Box>
        <Paper className={classes.emptyResultsPaper}>
          <Grid container alignItems="center">
            <Grid item xs={12} justify="center">
              <Box className={classes.emptyResultsContainer}>
                <Typography
                  variant="h4"
                  className={`${classes.textAlignCenter} ${isSmall ? classes.hb5 : classes.hb4}`}
                  color="primary"
                >
                  {t('emptyResultTitle')}
                </Typography>
                <Box mt={2}>
                  <Grid container alignItems="center">
                    <Grid item md={1} container></Grid>
                    <Grid item md={10} container>
                      <Typography
                        variant="body1"
                        style={{ margin: 'auto' }}
                        className={`${classes.textAlignCenter} ${classes.p4}`}
                      >
                        <span dangerouslySetInnerHTML={{ __html: `${t('introText')}` }}></span>
                      </Typography>
                    </Grid>
                    <Grid item md={1} container></Grid>
                  </Grid>
                </Box>
                <Box className={classes.emptyResultsBenefits}>
                  <Grid container alignItems="center">
                    <Grid item md={1} container></Grid>
                    <Grid item md={2} container className={classes.imgContainer}>
                      <img className={classes.icon} src="/img/yeild.svg" alt="grainmilling" />
                      <Box mt={2}>
                        <Typography variant="body1" className={`${classes.valueDriverText} ${classes.p4}`}>
                          <b>{t('savingsBenefit')}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} container className={classes.imgContainer}>
                      <img className={classes.icon} src="/img/energy.svg" alt="grainmilling" />
                      <Box mt={2}>
                        <Typography variant="body1" className={`${classes.valueDriverText} ${classes.p4}`}>
                          <b>{t('savingsEnergy')}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} container className={classes.imgContainer}>
                      <img className={classes.icon} src="/img/time.svg" alt="grainmilling" />
                      <Box mt={2}>
                        <Typography variant="body1" className={`${classes.valueDriverText} ${classes.p4}`}>
                          <b>{t('productionTime')}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} container className={classes.imgContainer}>
                      <img className={classes.icon} src="/img/plant.svg" alt="grainmilling" />
                      <Box mt={2}>
                        <Typography variant="body1" className={`${classes.valueDriverText} ${classes.p4}`}>
                          <b>{t('savingsEquipment')}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={2} container className={classes.imgContainer}>
                      <img className={classes.icon} src="/img/earth.svg" alt="grainmilling" />
                      <Box mt={2}>
                        <Typography variant="body1" className={`${classes.valueDriverText} ${classes.p4}`}>
                          <b>{t('savingsEarth')}</b>
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={1} container></Grid>
                  </Grid>
                </Box>
                {!isDesktop && (
                  <Box className={classes.centerButton}>
                    <Button small type="primary" id="cta_try_calculator" onClick={() => setOpenDrawer(true)}>
                      {t('tryCalculator')}
                    </Button>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </>
    );
  }
  return (
    <>
      <DownloadDialog show={showDownload} onClose={onSubmitDownload} appState={appState} />
      <ContactFormDialog
        show={showContact}
        onClose={onCloseContact}
        appState={appState}
        dialogTitle={t('contactTitle')}
        dialogIntro={t('contactDescription')}
      />

      <Grid container justify="space-between" alignItems="center">
        <Box mt={4} mb={4}>
          <Typography
            variant="h4"
            align={isDesktop ? 'left' : 'center'}
            className={isSmall ? classes.hb7 : classes.hb4}
          >
            <span dangerouslySetInnerHTML={{ __html: `${t('title')}` }}></span>
          </Typography>
        </Box>
        {isDesktop && (
          <Box display="flex" justifyContent="flex-start">
            <Button type="secondary" onClick={onPriceClick} id="plant_input_edit_price_CTA">
              {t('editPrices')}
            </Button>
          </Box>
        )}
      </Grid>

      <Fade in timeout={1000}>
        <Grid container component={Paper} alignItems="center" className={classes.grossBenefitsPaper}>
          <Grid item md={5} xs={12} className={`${classes.centerContent} ${classes.grossBenefitsContainer}`}>
            <Typography
              variant="h4"
              className={isSmall ? classes.p3 : classes.h4}
              style={{ textAlign: 'center', paddingTop: 16 }}
            >
              {t('yearlyGrossBenefits')}
            </Typography>
            <Typography
              variant="h2"
              color="primary"
              className={`${classes.grossBenefit} ${isSmall ? classes.hb4 : classes.hb2}`}
            >
              {formatLabel(grossbenefit)}
            </Typography>
          </Grid>
          <Grid item container md={7} xs={12} direction="row" style={{ height: isSmall ? '25rem' : '34.688rem' }}>
            <Grid item container wrap="nowrap" style={{ minHeight: !isSmall ? '6.5rem' : 'auto' }}>
              <Grid container item md={4} xs={3} alignItems="center" justify="center">
                <img className={classes.img} src="/img/yeild.svg" alt="grainmilling" />
              </Grid>
              <Grid item container justify="flex-start" alignItems="center" md={8} xs={9}>
                <Box className={classes.benefitsContainer}>
                  <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1}>
                    {t('benefitsRelatedTo')}
                  </Typography>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1} noWrap>
                      {t('increasedYield')}:{' '}
                    </Typography>
                    {appState.inputFields.region.value == Region.NorthAmerica ? (
                      <Typography
                        variant="h5"
                        color="primary"
                        className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                      >
                        {formatLabel(starchCost.net)}
                      </Typography>
                    ) : (
                      <Typography
                        variant="h5"
                        color="primary"
                        className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                      >
                        {formatLabel(starchCost.net + higherSales.net)}
                      </Typography>
                    )}
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid item container wrap="nowrap" style={{ minHeight: !isSmall ? '6.5rem' : 'auto' }}>
              <Grid item container justify="center" alignItems="center" md={4} xs={3}>
                <img className={classes.img} src="/img/energy.svg" alt="grainmilling" />
              </Grid>
              <Grid item container justify="flex-start" alignItems="center" md={8} xs={9}>
                <Box className={classes.benefitsContainer}>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1} noWrap>{`${t(
                      'energySavings'
                    )}: `}</Typography>
                    <Typography
                      variant="h5"
                      color="primary"
                      className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                    >{`${formatLabel(energySavings.net)}`}</Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
            <Grid item container wrap="nowrap" style={{ minHeight: !isSmall ? '6.5rem' : 'auto' }}>
              <Grid item container justify="center" alignItems="center" md={4} xs={3}>
                <img className={classes.img} src="/img/time.svg" alt="grainmilling" />
              </Grid>
              <Grid item container justify="flex-start" alignItems="center" md={8} xs={9}>
                <Box className={classes.benefitsContainer}>
                  {isSmall ? (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="body1" style={{ paddingRight: '8px' }} className={classes.p3}>{`${t(
                        'equipmentMaintainDays'
                      )}: `}</Typography>
                      <Typography
                        variant="h5"
                        color="primary"
                        className={`${classes.valueDriver} ${classes.hb5}`}
                      >{`${formatNumberString(flexibility.extraDays)} ${t('days')}`}</Typography>
                      {appState.inputFields.region.value == Region.NorthAmerica ? (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                          <Typography variant="body1" className={classes.p3} noWrap>
                            {`${t('benefitFromCapacity')}: `}
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                          >{` $ ${formatNumberString(flexibility.extraDaysInUSD, 0)}`}</Typography>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography variant="body1" className={classes.p1} noWrap>
                          {`${t('equipmentMaintainDays')}: `}
                        </Typography>
                        <Typography
                          variant="h5"
                          color="primary"
                          style={{ paddingRight: '8px' }}
                          className={`${classes.valueDriver} ${classes.hb4}`}
                        >{`${formatNumberString(flexibility.extraDays)} ${t('days')}`}</Typography>
                        <TypographyWithTooltip
                          variant="body1"
                          className={classes.p3}
                          style={{ display: 'flex', flexWrap: 'nowrap' }}
                          tooltip={`${t('benefitExtraCapacityToolTip')} `}
                        ></TypographyWithTooltip>
                        {/* Changes for 2022 */}
                        {appState.inputFields.region.value == Region.NorthAmerica ? (
                          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Typography variant="body1" className={classes.p1} noWrap>
                              {`${t('benefitFromCapacity')}: `}
                            </Typography>
                            <Typography
                              variant="h5"
                              color="primary"
                              className={`${classes.valueDriver} ${classes.hb4}`}
                            >{` $ ${formatNumberString(flexibility.extraDaysInUSD, 0)}`}</Typography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            {/* Changes for 2022 */}
            <Grid item container wrap="nowrap" style={{ minHeight: !isSmall ? '6.5rem' : 'auto' }}>
              <Grid item container justify="center" alignItems="center" md={4} xs={3}>
                <img className={classes.img} src="/img/plant.svg" alt="grainmilling" />
              </Grid>
              <Grid item container justify="flex-start" alignItems="center" md={8} xs={9}>
                <Box className={classes.benefitsContainer}>
                  {isSmall ? (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                          variant="h5"
                          color="primary"
                          className={`${classes.valueDriver} ${classes.hb5}`}
                          style={{ paddingLeft: '0px', paddingRight: '8px' }}
                        >
                          {`16%`}
                        </Typography>
                        <Typography variant="body1" className={classes.p3}>
                          {`${t('fiberPressLoad')} `}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                          variant="h5"
                          color="primary"
                          className={`${classes.valueDriver} ${classes.hb5}`}
                          style={{ paddingLeft: '0px', paddingRight: '8px' }}
                        >
                          {`27%`}
                        </Typography>
                        <Typography variant="body1" className={classes.p3}>
                          {`${t('fiberPressDryer')} `}
                        </Typography>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                          variant="h5"
                          color="primary"
                          className={`${classes.valueDriver} ${classes.hb4}`}
                          style={{ paddingLeft: '0px', paddingRight: '8px' }}
                        >{`16%`}</Typography>
                        <Typography variant="body1" className={classes.p1}>
                          {`${t('fiberPressLoad')} `}
                        </Typography>
                      </div>
                      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <Typography
                          variant="h5"
                          color="primary"
                          className={`${classes.valueDriver} ${classes.hb4}`}
                          style={{ paddingLeft: '0px', paddingRight: '8px' }}
                        >
                          {`27%`}
                        </Typography>
                        <Typography variant="body1" className={classes.p1}>
                          {`${t('fiberPressDryer')} `}
                        </Typography>
                      </div>
                      <></>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
            {appState.inputFields.region.value == Region.NorthAmerica ? (
              <Grid item container wrap="nowrap" style={{ minHeight: !isSmall ? '6.5rem' : 'auto' }}>
                <Grid
                  item
                  container
                  justify="center"
                  alignItems="center"
                  md={12}
                  xs={9}
                  style={{ minWidth: '99%', justifyContent: !isSmall ? 'center' : 'left' }}
                >
                  <Box className={classes.benefitsContainer} style={{ width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                      {!isSmall ? (
                        <div style={{ display: 'flex', flexWrap: 'nowrap', marginLeft: '1px' }}>
                          <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1} noWrap>
                            {`${t('payBackRetrofit')}: `}
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            style={{ paddingRight: '8px' }}
                            className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                          >
                            {`${formatNumberString(payBack.payBackTimeMonths)} months`}
                          </Typography>
                          <TypographyWithTooltip
                            variant="body1"
                            className={classes.p3}
                            style={{ display: 'flex', flexWrap: 'nowrap', marginLeft: '1px' }}
                            tooltip={`${t('payBackRetrofitToolTip')} `}
                          ></TypographyWithTooltip>
                        </div>
                      ) : (
                        <div style={{ flexWrap: 'wrap' }}>
                          <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1} noWrap>
                            {`${t('payBackRetrofit')}:  `}
                          </Typography>
                          <Typography
                            variant="h5"
                            color="primary"
                            className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                          >
                            {`${formatNumberString(payBack.payBackTimeMonths)} months`}
                          </Typography>
                        </div>
                      )}
                    </div>
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <></>
            )}
          </Grid>
        </Grid>
      </Fade>

      <Box my={3} />
      <Fade in timeout={1000}>
        <Grid container component={Paper} alignItems="center" className={classes.sustainabilityPaper}>
          <Grid item md={5} xs={12} className={`${classes.centerContent} ${classes.sustainabilityContainer}`}>
            <Typography variant="h4" className={classes.sustainabilityLabel}>
              {t('improvedSustainability')}
            </Typography>
            <Typography
              variant="h3"
              className={isSmall ? classes.hb4 : classes.hb3}
              style={{ textAlign: 'center' }}
              color="primary"
            >
              {formatNumberString(sustainability.cornProcessedOutput.lowerEmissionPercentage, 0)} Tons CO₂/year*
            </Typography>
          </Grid>
          <Grid item md={7} xs={12}>
            <Grid item container wrap="nowrap" style={{ height: isSmall ? '8.688rem' : '14rem' }}>
              <Grid container item md={4} xs={3} justify="center" alignItems="center">
                <img className={classes.imgEarth} src="/img/earth.svg" alt="grainmilling" />
              </Grid>
              <Grid item container justify="flex-start" alignItems="center" md={8} xs={9}>
                <Box>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="body1" className={isSmall ? classes.p3 : classes.p1}>
                      {t('gasEmissions')}:
                    </Typography>
                    <Typography
                      variant="h4"
                      className={`${classes.valueDriver} ${isSmall ? classes.hb5 : classes.hb4}`}
                      color="primary"
                      style={{ paddingLeft: 0 }}
                    >
                      {formatNumberString(-sustainability.cornProcessedOutput.total, 1)}
                      kg CO₂/ton <br />
                      corn ground
                    </Typography>
                  </div>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Fade>
      <Box my={'8px'} />
      <Fade in timeout={1800}>
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.p5} style={{ paddingLeft: '4px' }}>
              *Overall CO₂ emissions generated from corn cultivation to starch production based on LCA study, adapted to
              reflect global scope and new trial data. The value might differ based on fuel source for steam production
              and electricity mix.
            </Typography>
          </Grid>
        </Grid>
      </Fade>
      <Box my={'12px'} />
      <Fade in timeout={1800}>
        <Grid container>
          <Grid item xs={12} className={classes.buttonsContainer}>
            <Box className={classes.bookingButton}>
              <Button small type="secondary" id="cta_benefit_walkthrough_bottom" onClick={onClickContact}>
                {t('bookAMeetingCTA')}
              </Button>
            </Box>
            <Button small type="primary" id="cta_download_report" onClick={onClickDownload}>
              {t('downloadPDFCTA')}
            </Button>
          </Grid>
        </Grid>
      </Fade>
      <Box id="cta_download_report">
        {generatePdf && (
          <>
            <Loading overlay overlayBackground={'rgba(1,1,1,0.8)'} height={document.body.scrollHeight} />
            <Pdf
              grossbenefit={grossbenefit}
              higherSales={higherSales}
              energySavings={energySavings}
              starchCost={starchCost}
              sustainability={cloneSustainability}
              extraDaysBenefit={`${formatNumberString(flexibility.extraDays)} days`}
              payBackMonths={`${formatNumberString(payBack.payBackTimeMonths)}`}
              region={`${appState.inputFields.region.value.toString()}`}
              formatLabel={formatLabel}
              onDownloaded={(): void => setGeneratePdf(false)}
              appState={appstateClone}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default BenefitDisplay;
