import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import enTranslations from 'translations/en.json';
import turkishTranslations from 'translations/tr.json';

const translations = {
  en: enTranslations,
  tr: turkishTranslations,
};

i18n.use(initReactI18next).init({
  resources: translations,
  fallbackLng: 'en',
  lng: window.navigator.language,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false, // not needed for react
    formatSeparator: ',',
  },
  react: {
    wait: true,
  },
});

export default i18n;
