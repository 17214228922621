/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import saveSvg from 'save-svg-as-png/lib/saveSvgAsPng';
import ReactDOM from 'react-dom';

const encodeChart = async (id: string): Promise<string> => {
  // A Recharts component is rendered as a div that contains namely an SVG
  // which holds the chart.
  if (ReactDOM == null) {
    return '';
  }

  // eslint-disable-next-line react/no-find-dom-node,no-undef
  const chartNode = document.getElementById(id);
  if (!chartNode) {
    return '';
  }

  const chartSVG = chartNode?.children[0].children[0];
  if (!chartSVG) {
    return '';
  }

  // eslint-disable-next-line no-undef
  const dataUri: string = await saveSvg.svgAsPngUri(chartSVG, {
    encoderOptions: 1,
  });
  return dataUri;
};

export default encodeChart;
