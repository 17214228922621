import { Dictionary } from 'types/Dictionary';
import { CalculateStaticData, CalculateInput } from 'calculation/CalculationTypes';

export type YeildSaving = {
  production: number;
  revenue: number;
};

export const yeildSaving = (production: number, financialInput: number): YeildSaving => ({
  production,
  revenue: financialInput * production,
});

export const yeildBeforeFrontia = (
  inputStatic: CalculateStaticData,
  inputUser: CalculateInput
): Dictionary<YeildSaving> => {
  const beforeYeild: Dictionary<YeildSaving> = {};

  beforeYeild.corn = yeildSaving(
    -inputStatic.plantCapacityData.millingRate / (1 - inputStatic.plantCapacityData.cornMoisture),
    inputStatic.financialData.Corn
  );

  beforeYeild.csl = yeildSaving(
    (-beforeYeild.corn.production *
      (1 - inputStatic.plantCapacityData.cornMoisture) *
      (1 - inputUser.excessCornSteepLiqour) *
      inputStatic.processData.CSL) /
      (1 - inputStatic.endProducts.CSL),
    inputStatic.financialData.CSL
  );

  beforeYeild.germ = yeildSaving(
    inputStatic.plantCapacityData.millingRate * (inputStatic.processData.Germ / (1 - inputStatic.endProducts.Germ)),
    inputStatic.financialData.Germ
  );

  beforeYeild.cgm = yeildSaving(
    inputStatic.plantCapacityData.millingRate * (inputStatic.processData.CGM / (1 - inputStatic.endProducts.CGM)),
    inputStatic.financialData.CGM
  );

  beforeYeild.cgf = yeildSaving(
    (inputStatic.plantCapacityData.millingRate *
      (inputStatic.processData.Fiber + inputStatic.processData.CSL * inputUser.excessCornSteepLiqour)) /
      (1 - inputStatic.endProducts.CGF),
    inputStatic.financialData.CGF
  );

  beforeYeild.starch = {
    production:
      inputStatic.plantCapacityData.millingRate *
      (inputStatic.processData.Starch / (1 - inputStatic.endProducts.Starch)),
    revenue: -(
      beforeYeild.corn.revenue +
      beforeYeild.csl.revenue +
      beforeYeild.germ.revenue +
      beforeYeild.cgm.revenue +
      beforeYeild.cgf.revenue
    ),
  };

  return beforeYeild;
};
