import { CalculateInput } from 'calculation/CalculationTypes';
import { Region } from 'staticData/Region';

export type PlantCapacityData = {
  millingRate: number;
  cornMoisture: number;
  plantMilling: number;
  clsFiberFrontia: number;
};

const plantCapacityData = (input: CalculateInput): PlantCapacityData => ({
  millingRate: (input.region == Region.NorthAmerica ? input.totalCapcity / 39.368 : input.totalCapcity) * (1 - 0.145),
  cornMoisture: 0.145,
  plantMilling: input.region == Region.NorthAmerica ? input.totalCapcity / 39.368 : input.totalCapcity,
  clsFiberFrontia: 1,
});

export default plantCapacityData;
