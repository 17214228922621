import React, { ReactElement } from 'react';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.common.white,
    maxWidth: '928px',
    // paddingTop: theme.spacing(10),
    paddingLeft: '0px',
    paddingRight: '0px',
    [theme.breakpoints.down('md')]: {
      width: '80%',
      marginBottom: theme.spacing(4),
    },
  },
}));

type BodyProps = {
  children: ReactElement;
};

const Body = ({ children, ...props }: BodyProps): ReactElement => {
  const classes = useStyles();

  return (
    <>
      <Container fixed {...props} className={classes.container}>
        {children}
      </Container>
    </>
  );
};

export default Body;
