import {
  CalculateInput,
  CalculateStaticData,
  CalculatePriceInput,
  SustainabilityOutput,
} from 'calculation/CalculationTypes';
import getStaticData from 'calculation/presetData/PresetDataHelper';
import Converter from 'utils/Converter';
import transformUserInput, { transformPricingData } from './comon/UserInputTransform';
import calculateSustainability from './CalculateSustainability';

const calculateSustainabilityData = (
  input: CalculateInput,
  priceInput: CalculatePriceInput,
  converter: Converter
): SustainabilityOutput => {
  const transformedInput = transformUserInput(input, converter);

  const { NativeStarch, ...additionalPrices } = priceInput;
  const originalStaticData = getStaticData(transformedInput);
  originalStaticData.financialData = {
    ...originalStaticData.financialData,
    ...additionalPrices,
  };
  originalStaticData.nativeStarchData.nativeStarchPrice = NativeStarch;

  const staticData: CalculateStaticData = transformPricingData(originalStaticData, transformedInput.region, converter);

  const sustainability = calculateSustainability(staticData, transformedInput);

  const output: SustainabilityOutput = {
    cornProcessedOutput: sustainability.cornProcessedOutput,
    starchProducedOutput: sustainability.starchProducedOutput,
  };

  return output;
};

export default calculateSustainabilityData;
