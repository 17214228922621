/* eslint-disable max-len */
import React, { useEffect, ReactElement, useState } from 'react';
import ReactPDF, { Document, Page, Text, PDFDownloadLink, StyleSheet, View, Image, Font } from '@react-pdf/renderer';
import { useTheme, Theme } from '@material-ui/core';
import { CalculateOutput, SustainabilityOutput } from 'calculation/CalculationTypes';
import { AppState, YesNo, InputField } from 'appState/AppStateTypes';
import { Region } from 'staticData/Region';
import { FuelType } from 'staticData/Fuel';
import encodeChart from 'utils/SvgConvert';
import { useTranslation } from 'react-i18next';
import IncreasedYieldChart from './charts/IncreasedYieldChart';
import BreakDownChart from './charts/BreakDownChart';
import EnergySavingsChart from './charts/EnergySavingsChart';
import GlobalWarmingChart from './charts/GlobalWarmingChart';
import { frontiaColors } from 'theme/colors';
import { formatNumberString } from '../utils/NumberHelper';

export interface ChartsEncoded {
  costChart: string;
  performanceChart: string;
  footprintChart: string;
}

export interface PdfProps {
  grossbenefit: CalculateOutput['grossbenefit'];
  higherSales: CalculateOutput['higherSales'];
  energySavings: CalculateOutput['energySavings'];
  starchCost: CalculateOutput['starchCost'];
  sustainability: SustainabilityOutput;
  payBackMonths: string;
  region: string;
  extraDaysBenefit: string;
  onDownloaded(): void;
  formatLabel(value: number, decimals?: number): string;
  appState: AppState;
}

interface DocProps {
  grossbenefit: string;
  salesAndYeild: string;
  energySavings: string;
  grossBenefitchart: string;
  extraDaysBenefit: string;
  theme: Theme;
  energyChart: string;
  increasedYieldChart: string;
  cornProcessedChart: string;
  starchProcessedChart: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  userInputs: { label: string; value: any }[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  priceInputs: { label: string; value: any }[];
  sustainabilityData: SustainabilityOutput;
  payBackMonths: string;
  region: string;
}

interface YearlyGrossBenefitsProps {
  grossbenefit: string;
  salesAndYeild: string;
  energySavings: string;
  chart: string;
  payBackMonths: string;
  styles: ReactPDF.Styles;
  region: string;
  extraDaysBenefit: string;
}

const BREAKDOWN_CHART_ID = 'break_down_chart';
const ENERGY_CHART_ID = 'energy_chart';
const INCREASED_YIELD_CHART_ID = 'increased_yield_chart';
const CORN_PROCESSED_ID = 'corn_processed';
const STARCH_PROCESSED_ID = 'starch_processed';

const YearlyGrossBenefits = ({
  grossbenefit,
  payBackMonths,
  salesAndYeild,
  energySavings,
  chart,
  styles,
  region,
  extraDaysBenefit,
}: YearlyGrossBenefitsProps): ReactElement => {
  const { t } = useTranslation('benefitDisplay');
  return (
    <View style={styles.grossBenefitCard}>
      <View style={styles.flexColumnCenter}>
        <Text style={styles.h4}>{t('grossBenefit')}:</Text>
        <Text style={[styles.hb4, styles.colorMain]}>{`${grossbenefit} / year`}</Text>
      </View>
      <Image style={{ width: 400 }} src={chart} />
      <View style={{ display: 'flex', flexDirection: 'column' }}>
        <View style={[styles.flexRowCenter, { marginBottom: '8px' }]}>
          <Circle color={frontiaColors.primaryGreen} />
          <Text style={styles.p4}>{`${t('benefitsIncreasedYield')}: ${salesAndYeild}`}</Text>
        </View>
        <View style={[styles.flexRowCenter, { marginBottom: '8px' }]}>
          <Circle color={frontiaColors.grayBlue} />
          <Text style={styles.p4}>{`${t('energySavings')}: ${energySavings}`}</Text>
        </View>
        <View style={[styles.flexRowCenter, { marginBottom: '8px' }]}>
          <Bullet color={frontiaColors.primaryBlue} />
          <Text style={styles.p4}>{`Extra days for equipment maintenance: ${extraDaysBenefit}`}</Text>
        </View>
        {/* Changes for 2022 for NA */}
        {region === '1' ? (
          <div>
            <View style={styles.flexRowCenter}>
              <Bullet color={frontiaColors.primaryBlue} />
              <Text style={styles.p4}>{`Payback time on retrofit investment: ${payBackMonths} months`}</Text>
            </View>
          </div>
        ) : (
          <></>
        )}
      </View>
    </View>
  );
};

const Circle = ({ color, size = 10, bulletPoint = false }: { color: string; size?: number; bulletPoint?: boolean }) => {
  return (
    <Text
      style={{
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: 50,
        marginRight: 8,
        marginTop: bulletPoint ? 4 : 0,
      }}
    ></Text>
  );
};
const Bullet = ({ color, size = 10, bulletPoint = true }: { color: string; size?: number; bulletPoint?: boolean }) => {
  return (
    <Text
      style={{
        height: size,
        width: size,
        backgroundColor: color,
        borderRadius: 0,
        marginRight: 8,
        marginTop: bulletPoint ? 4 : 0,
      }}
    ></Text>
  );
};

const Header = ({ styles }: { styles: ReactPDF.Styles }): ReactElement => {
  const { t } = useTranslation('pdf');
  return (
    <View fixed style={styles.header}>
      <Image src="/logo.png" style={styles.logo} />
      <Text style={styles.headerTitle}>{t('headerTitle')}</Text>
      <Text
        style={[styles.p3, styles.whiteFont]}
        fixed
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </View>
  );
};
const ScenariosWrapper = ({
  styles,
  sustainabilityprop,
}: {
  styles: ReactPDF.Styles;
  sustainabilityprop: number;
}): ReactElement => {
  const { t } = useTranslation('benefitDisplay');
  return (
    <View style={styles.scenariosContainer}>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <Text style={[styles.hb6, styles.blackFont]}>{`${t('Sustainability Benefit')}: `}</Text>
        <Text style={[styles.p3, styles.blackFont]}>
          {formatNumberString(sustainabilityprop, 0)}
          {` tons CO₂/year`}
        </Text>
        {/* <Text style={[styles.p3, styles.blackFont]}>{t( ' coProductsReduced')}</Text> */}
      </View>

      <View style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
        <View>
          <Text style={styles.p5}>{t('introLCA')}</Text>
        </View>
        <Text style={styles.p5}>{`${t('introChangesLCA')}:`}</Text>
        <View style={styles.flexRowStart}>
          <Circle color={'#000'} size={5} bulletPoint />
          <Text style={[styles.p5, { width: '350px' }]}>{t('firstLCAChange')}</Text>
        </View>
        <View style={styles.flexRowStart}>
          <Circle color={'#000'} size={5} bulletPoint />
          <Text style={[styles.p5, { width: '350' }]}>{t('secondLCAChange')}</Text>
        </View>
        <View style={styles.flexRowStart}>
          <Circle color={'#000'} size={5} bulletPoint />
          <Text style={[styles.p5, { width: '350px' }]}> {t('fourthLCAChange')}</Text>
        </View>
        <View style={styles.flexRowStart}>
          <Circle color={'#000'} size={5} bulletPoint />
          <Text style={[styles.p5, { width: '350px' }]}> {t('thirdLCAChange')}</Text>
        </View>
        <View>
          <Text style={{ paddingTop: '12px' }}>
            <Text style={styles.hb9}>{`${t('reference')}: `}</Text>
            <Text style={styles.p6}> {t('LCAReference')}</Text>
          </Text>
        </View>
      </View>
    </View>
  );
};
const InputValuesWrapper = ({
  styles,
  userInputs,
  priceInputs,
}: {
  styles: ReactPDF.Styles;
  userInputs: { label: string; value: number }[];
  priceInputs: { label: string; value: number }[];
}) => {
  const { t } = useTranslation('pdf');
  return (
    <View style={{ padding: '24 48', width: '60%' }}>
      <View>
        <Text style={[styles.hb6, styles.blackFont, { paddingBottom: 20 }]}>{t('parametersUsed')}</Text>
      </View>
      <View style={styles.table}>
        {userInputs.map(({ label, value }, index) => (
          <View key={index} style={styles.tr}>
            <View style={styles.td}>
              <Text>{`${label}`}</Text>
            </View>
            <View style={styles.td}>
              <Text>{`${value}`}</Text>
            </View>
          </View>
        ))}
        {priceInputs.map(({ label, value }, index) => (
          <View key={index} style={styles.tr}>
            <View style={styles.td}>
              <Text>{`${label}`}</Text>
            </View>
            <View style={styles.td}>
              <Text>{`${value}`}</Text>
            </View>
          </View>
        ))}
      </View>
    </View>
  );
};

const Doc = ({
  theme,
  grossBenefitchart,
  grossbenefit,
  salesAndYeild,
  energySavings,
  energyChart,
  increasedYieldChart,
  starchProcessedChart,
  userInputs,
  priceInputs,
  sustainabilityData,
  payBackMonths,
  region,
  extraDaysBenefit,
}: DocProps): ReactElement => {
  Font.register({
    family: 'Novozymes',
    fonts: [{ src: '/fonts/Novozymes-Regular.ttf' }, { src: '/fonts/Novozymes-Bold.ttf', fontWeight: 700 }],
  });

  const styles = StyleSheet.create({
    global: {
      fontFamily: 'Novozymes',
    },
    cover: {
      height: '595.28px',
      width: '841.89px',
    },
    header: {
      backgroundColor: theme.palette.secondary.main,
      height: '48px',
      padding: '14px 48px',
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row',
      alignItems: 'center',
    },
    headerTitle: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.common.white,
    },
    logo: {
      width: '70px',
      height: '20px',
    },
    whiteFont: {
      color: theme.palette.common.white,
    },
    blackFont: {
      color: theme.palette.text.primary,
    },
    h1: {
      fontWeight: 700,
      fontSize: 26,
      lineHeight: 1.2,
      marginBottom: 10,
    },
    h2: {
      fontWeight: 500,
      fontSize: 32,
      lineHeight: 1.2,
    },
    h3: {
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.5,
      marginBottom: 10,
    },
    h4: {
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 400,
      fontSize: 24,
    },
    hb4: {
      color: 'rgba(0, 0, 0, 0.8)',
      fontWeight: 700,
      fontSize: 24,
    },
    hb5: {
      fontSize: 18,
      fontWeight: 700,
      color: 'rgba(0, 0, 0, 0.8)',
      lineHeight: 1.5,
      marginBottom: 10,
    },
    hb6: {
      fontSize: 16,
      fontWeight: 700,
      color: theme.palette.secondary.main,
      lineHeight: 1.5,
    },
    hb8: {
      fontSize: 12,
      fontWeight: 700,
      lineHeight: 1.5,
      color: 'rgba(0, 0, 0, 0.8)',
    },
    hb9: {
      fontSize: 8,
      fontWeight: 700,
      lineHeight: 1.5,
    },
    p3: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.2,
    },
    p4: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.2,
    },
    p5: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.2,
    },
    p6: {
      fontSize: 8,
      fontWeight: 400,
      color: 'rgba(0, 0, 0, 0.8)',
      lineHeight: 1.6,
    },
    pb6: {
      fontSize: 10,
      fontWeight: 700,
      color: 'rgba(0, 0, 0, 0.8)',
      lineHeight: 1.6,
    },
    body2: {
      fontSize: 10,
      fontWeight: 400,
      lineHeight: 1.43,
    },
    grossBenefitCard: {
      padding: '48 60 64 60',
      width: '50%',
      flexDirection: 'column',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: frontiaColors.bg3,
    },
    scenariosContainer: {
      padding: '24 40 40 40',
      width: '500px',
      flexDirection: 'column',
      display: 'flex',
      backgroundColor: frontiaColors.bg3,
    },
    lifeCycleContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      width: '533px',
      padding: '24px 40px 0px 32px',
    },
    colorMain: {
      color: frontiaColors.primaryGreenHover,
    },
    tr: { display: 'flex', flexDirection: 'row', justifyContent: 'space-between', fontSize: 10, fontWeight: 400 },
    td: { padding: 5, width: '50%', border: `1 solid ${theme.palette.grey[300]}` },
    coverTextContainer: {
      position: 'absolute',
      top: '110px',
      left: '48px',
    },
    coverExtractContainer: {
      position: 'absolute',
      bottom: '24px',
      right: '56px',
    },
    coverText: {
      color: theme.palette.secondary.main,
      fontSize: 34,
      lineHeight: 1.43,
    },
    coverTextBold: {
      color: theme.palette.secondary.main,
      fontSize: 34,
      lineHeight: 1.43,
      fontWeight: 700,
    },
    flexRowCenter: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    flexColumnCenter: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    flexRowStart: {
      display: 'flex',
      flexDirection: 'row',
    },
  });

  const { t } = useTranslation('pdf');
  const { t: benefitDisplayTranslatons } = useTranslation('benefitDisplay');

  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.global}>
        <View>
          <Image src="/img/cornfield.png" style={styles.cover} />
          <View style={styles.coverTextContainer}>
            <Text style={styles.coverText}>{t('coverTitle')}</Text>
            <Text style={styles.coverTextBold}>{t('coverTitleBold')}</Text>
          </View>
          <View style={styles.coverExtractContainer}>
            <Text style={styles.hb6}>{`${t('extractAsOf')} ${new Date().toUTCString()}`}</Text>
          </View>
        </View>
      </Page>
      <Page size="A4" orientation="landscape" wrap={false} style={styles.global}>
        <Header styles={styles} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            height: '547.28px',
            width: '100%',
          }}
        >
          <YearlyGrossBenefits
            chart={grossBenefitchart}
            grossbenefit={grossbenefit}
            salesAndYeild={salesAndYeild}
            energySavings={energySavings}
            payBackMonths={payBackMonths}
            region={region}
            styles={styles}
            extraDaysBenefit={extraDaysBenefit}
          />
          <View
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '24 32 100 32',
            }}
          >
            <View>
              <Text style={styles.hb5}>{benefitDisplayTranslatons('breakdownHeading')}</Text>
            </View>
            <View
              style={[
                styles.flexRowCenter,
                {
                  height: 155,
                },
              ]}
            >
              <Text style={[styles.hb8, { width: '30%' }]}>{benefitDisplayTranslatons('increasedYieldBenefit')}</Text>
              <Image style={{ width: '80%', height: '100%' }} src={increasedYieldChart} />
            </View>
            <View
              style={[
                styles.flexRowCenter,
                {
                  height: 155,
                },
              ]}
            >
              <Text style={[styles.hb8, { width: '30%' }]}>{benefitDisplayTranslatons('energySavings')}</Text>
              <Image style={{ width: '80%', height: '100%' }} src={energyChart} />
            </View>
          </View>
        </View>
      </Page>

      <Page size="A4" orientation="landscape" style={styles.global}>
        <Header styles={styles} />
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '547.28px',
            width: '100%',
          }}
        >
          <ScenariosWrapper
            styles={styles}
            sustainabilityprop={sustainabilityData.cornProcessedOutput.lowerEmissionPercentage}
          />
          <View style={styles.lifeCycleContainer}>
            <View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <Text style={[styles.hb8]}>Impact of Frontia® Prime on corn wet milling process – Kg CO₂/MT corn</Text>
              </View>
              <View style={styles.flexRowCenter}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '80%',
                    height: 271,
                  }}
                >
                  <Image style={{ width: '125%', height: '80%' }} src={starchProcessedChart} />
                  <Text style={styles.p5}>{t('LCAtip')}</Text>
                </View>
              </View>
            </View>
          </View>
        </View>
      </Page>

      <Page size="A4" orientation="landscape" style={styles.global}>
        <Header styles={styles} />
        <View style={{ display: 'flex', flexDirection: 'row' }}>
          <InputValuesWrapper styles={styles} userInputs={userInputs} priceInputs={priceInputs} />
          <View style={{ width: '40%' }}>
            <Image src="/img/frontPagePdf.png" style={{ width: '100%', height: '547.28px' }} />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const Download = ({ onDownloaded }: { onDownloaded(): void }): JSX.Element => {
  useEffect(() => {
    // eslint-disable-next-line no-undef,no-unused-expressions
    document.getElementById('pdf_download')?.click();
    const timeout = setTimeout(() => {
      onDownloaded();
    }, 1000);

    return () => clearTimeout(timeout);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id="pdf_download" />;
};

const formatValue = (name: InputField['name'], value: InputField['value']): number | string => {
  switch (name) {
    case 'region':
      return Region[value];
    case 'fuelType':
      return FuelType[value];
    case 'produceEthanol':
    case 'feedDryer':
    case 'sellSteepLiqour':
      return YesNo[value];
    case 'excessCornSteepLiqour':
      return `${value}%`;
    default:
      return value;
  }
};

const Pdf = ({
  onDownloaded,
  formatLabel,
  grossbenefit,
  higherSales,
  energySavings,
  starchCost,
  extraDaysBenefit,
  appState,
  sustainability,
  payBackMonths,
  region,
}: PdfProps): ReactElement => {
  const theme = useTheme();
  const { t } = useTranslation('benefitDisplay');

  const [encodedBreakDownChart, setEncodedBreakDownChart] = useState('');
  const [encodedEnergyChart, setEncodedEnergyChart] = useState('');
  const [encodedIncreasedYieldChart, setEncodedIncreasedYieldChart] = useState('');
  const [encodedCornProcessedChart, setEncodedCornProcessedChart] = useState('');
  const [encodedStarchProcessedChart, setEncodedStarchProcessedChart] = useState('');
  const [isDecoding, setIsDecoding] = useState(true);

  useEffect(() => {
    const encodeCharts = () => {
      setTimeout(async () => {
        const breakDownChart = await encodeChart(BREAKDOWN_CHART_ID);
        const energySavingsChart = await encodeChart(ENERGY_CHART_ID);
        const increasedYieldChart = await encodeChart(INCREASED_YIELD_CHART_ID);
        const cornProcessedChart = await encodeChart(CORN_PROCESSED_ID);
        const starchProcessedChart = await encodeChart(STARCH_PROCESSED_ID);
        setEncodedBreakDownChart(breakDownChart);
        setEncodedEnergyChart(energySavingsChart);
        setEncodedIncreasedYieldChart(increasedYieldChart);
        setEncodedCornProcessedChart(cornProcessedChart);
        setEncodedStarchProcessedChart(starchProcessedChart);
        setIsDecoding(false);
      }, 0);
    };
    encodeCharts();
  }, []);

  const userInputs = Object.values(appState.inputFields)
    .filter((feild) => !feild.hidden)
    .map((field) => ({ label: field.label, value: formatValue(field.name, field.value) }));

  const priceInputs = Object.values(appState.priceInputFields)
    .filter((feild) => !feild.hidden)
    .map((field) => ({ label: field.label, value: formatLabel(field.value, 4) }));

  if (isDecoding)
    return (
      <div style={{ paddingTop: '15rem' }}>
        <BreakDownChart
          id={BREAKDOWN_CHART_ID}
          chartData={[
            { name: t('energySavings') as string, value: energySavings.net },
            { name: t('benefitsIncreasedYield') as string, value: starchCost.net + higherSales.net },
          ]}
          formatLabel={formatLabel}
        />
        <IncreasedYieldChart
          id={INCREASED_YIELD_CHART_ID}
          height={300}
          width="90%"
          chartDataStarch={starchCost}
          chartDataSales={higherSales}
        />
        <EnergySavingsChart id={ENERGY_CHART_ID} height={300} width="90%" chartData={energySavings} />
        <GlobalWarmingChart id={CORN_PROCESSED_ID} chartData={sustainability.cornProcessedOutput} />
        <GlobalWarmingChart id={STARCH_PROCESSED_ID} chartData={sustainability.starchProducedOutput} />
      </div>
    );

  return (
    <>
      <PDFDownloadLink
        document={
          <Doc
            theme={theme}
            grossbenefit={formatLabel(grossbenefit)}
            salesAndYeild={formatLabel(higherSales.net + starchCost.net)}
            energySavings={formatLabel(energySavings.net)}
            extraDaysBenefit={extraDaysBenefit}
            grossBenefitchart={encodedBreakDownChart}
            energyChart={encodedEnergyChart}
            increasedYieldChart={encodedIncreasedYieldChart}
            cornProcessedChart={encodedCornProcessedChart}
            starchProcessedChart={encodedStarchProcessedChart}
            userInputs={userInputs}
            priceInputs={priceInputs}
            sustainabilityData={appState.sustainabilityData}
            payBackMonths={payBackMonths}
            region={region}
          />
        }
        fileName="Frontia.pdf"
      >
        {({ loading }): ReactElement => (loading ? <></> : <Download onDownloaded={onDownloaded} />)}
      </PDFDownloadLink>
    </>
  );
};

export default Pdf;
