import { CalculateOutput, SustainabilityOutput } from 'calculation/CalculationTypes';

export enum InputFieldType {
  number,
  select,
}

export enum YesNo {
  yes,
  no,
  None = NaN,
}

export type InputOption = {
  label: string;
  value: number;
};

export type InputData = {
  region: InputField;
  daysOperating: InputField;
  daysFullCapacity: InputField;
  totalCapcity: InputField;
  produceEthanol: InputField;
  feedDryer: InputField;
  sellSteepLiqour: InputField;
  excessCornSteepLiqour: InputField;
  fuelType: InputField;
  // fiberMoisture: InputField;
  // totalStarchInFiber: InputField;
  // cslSprayOnFiber: InputField;
  // frontiaJadeDosage: InputField;
};

export type PriceInput = {
  Corn: InputField;
  CGF: InputField;
  CSL: InputField;
  CGM: InputField;
  Germ: InputField;
  Ethanol: InputField;
  NativeStarch: InputField;
};

export type Field = {
  name: string;
  value: number;
};

export type InputField = {
  name: string;
  default: number | string;
  label: string;
  tooltip?: string;
  value: number;
  disabled?: boolean;
  adornment?: string;
  hidden?: boolean;
  type: InputFieldType;
  options?: InputOption[];
  dataId?: string;
};

export type AppState = {
  priceInputFields: PriceInput;
  inputFields: InputData;
  calculateData: CalculateOutput;
  sustainabilityData: SustainabilityOutput;
};
