import { Region } from 'staticData/Region';

export type EnergyData = {
  fiberDryer: number;
  germDryer: number;
  cgmDryer: number;
  electricityRatio: number;
  priceGas: number;
  priceCoal: number;
  priceElectricity: number;
  gasEnergyUtilization: number;
  boilerYield: number;
};

const europe: EnergyData = {
  fiberDryer: 1100,
  germDryer: 1500,
  cgmDryer: 1800,
  electricityRatio: 75,
  priceGas: 100,
  priceCoal: 29.48,
  priceElectricity: 103,
  gasEnergyUtilization: 0.9,
  boilerYield: 0.9,
};

const northAmerica: EnergyData = {
  fiberDryer: 1100,
  germDryer: 1500,
  cgmDryer: 1800,
  electricityRatio: 75,
  priceGas: 10.69,
  priceCoal: 29.48,
  priceElectricity: 30,
  gasEnergyUtilization: 0.9,
  boilerYield: 0.9,
};

const middleEast: EnergyData = {
  fiberDryer: 1100,
  germDryer: 1500,
  cgmDryer: 1800,
  electricityRatio: 75,
  priceGas: 100,
  priceCoal: 29.48,
  priceElectricity: 103,
  gasEnergyUtilization: 0.9,
  boilerYield: 0.9,
};

const latinAmerica: EnergyData = {
  fiberDryer: 1100,
  germDryer: 1500,
  cgmDryer: 1800,
  electricityRatio: 75,
  priceGas: 100,
  priceCoal: 29.48,
  priceElectricity: 103,
  gasEnergyUtilization: 0.9,
  boilerYield: 0.9,
};

const chinaAPAC: EnergyData = {
  fiberDryer: 1100,
  germDryer: 1500,
  cgmDryer: 1800,
  electricityRatio: 75,
  priceGas: 100,
  priceCoal: 29.48,
  priceElectricity: 103,
  gasEnergyUtilization: 0.9,
  boilerYield: 0.9,
};

const getEnergyData = (region: Region): EnergyData => {
  switch (region) {
    case Region.Europe:
      return europe;
    case Region.NorthAmerica:
      return northAmerica;
    case Region.MiddleEast:
      return middleEast;
    case Region.LatinAmerica:
      return latinAmerica;
    case Region.APAC:
      return chinaAPAC;

    default:
      throw Error(`getEnergyData - unsupported region: ${region}`);
  }
};

export default getEnergyData;
