import { Dictionary } from 'types/Dictionary';
import { YesNo } from 'appState/AppStateTypes';
import { FuelType } from 'staticData/Fuel';
import { CalculateStaticData, CalculateInput } from './CalculationTypes';
import { YeildSaving, yeildBeforeFrontia, yeildSaving } from './comon/ProcessYeildSavings';
import { EnergySaving, computeExtraClsDrying, byproductSaving, ByproductSaving } from './comon/EnergySaving';

export const moreStarchAfterYeild = (
  inputStatic: CalculateStaticData,
  beforeYeild: Dictionary<YeildSaving>
): Dictionary<YeildSaving> => {
  const afterYeild: Dictionary<YeildSaving> = {};

  afterYeild.corn = yeildSaving(beforeYeild.corn.production, inputStatic.financialData.Corn);

  afterYeild.csl = yeildSaving(
    (-afterYeild.corn.production * (1 - inputStatic.plantCapacityData.clsFiberFrontia) * inputStatic.processData.CSL) /
      (1 - inputStatic.endProducts.CSL),
    inputStatic.financialData.CSL
  );

  afterYeild.germ = yeildSaving(
    (-afterYeild.corn.production * (1 - inputStatic.plantCapacityData.cornMoisture) * inputStatic.processData.Germ) /
      (1 - inputStatic.endProducts.Germ),
    inputStatic.financialData.Germ
  );

  afterYeild.cgm = yeildSaving(
    (-afterYeild.corn.production *
      (1 - inputStatic.plantCapacityData.cornMoisture) *
      (inputStatic.processData.CGM + inputStatic.enzymeData.gluten.recovered)) /
      (1 - inputStatic.endProducts.CGM),
    inputStatic.financialData.CGM
  );

  // Extra starch produced
  const afterYeildStarchProd =
    (-afterYeild.corn.production *
      (1 - inputStatic.plantCapacityData.cornMoisture) *
      (inputStatic.processData.Starch + inputStatic.enzymeData.starch.recovered)) /
    (1 - inputStatic.endProducts.Starch);

  afterYeild.extraStarchTcoDay = {
    production: afterYeildStarchProd - beforeYeild.starch.production,
    revenue: -100,
  };
  afterYeild.extraStarchTdsDay = {
    production: afterYeild.extraStarchTcoDay.production * (1 - inputStatic.endProducts.Starch),
    revenue: -100,
  };

  afterYeild.cgf = yeildSaving(
    -(
      (afterYeild.corn.production *
        (1 - inputStatic.plantCapacityData.cornMoisture) *
        (inputStatic.processData.Fiber +
          inputStatic.processData.CSL * inputStatic.plantCapacityData.clsFiberFrontia -
          inputStatic.enzymeData.starch.recovered -
          inputStatic.enzymeData.gluten.recovered)) /
      (1 - inputStatic.endProducts.CGF)
    ) +
      (afterYeild.extraStarchTdsDay.production * 0.5672 * inputStatic.ethonalData.lossStillageFermentation) /
        (1 - inputStatic.endProducts.CGF),
    inputStatic.financialData.CGF
  );

  afterYeild.starch = {
    production: afterYeildStarchProd,
    revenue: -(
      afterYeild.corn.revenue +
      afterYeild.csl.revenue +
      afterYeild.germ.revenue +
      afterYeild.cgm.revenue +
      afterYeild.cgf.revenue
    ),
  };

  return afterYeild;
};

export const moreStarchEnergySavings = (
  inputUser: CalculateInput,
  beforeYeild: Dictionary<YeildSaving>,
  afterYeild: Dictionary<YeildSaving>,
  inputStatic: CalculateStaticData,
  extraClsDrying: Dictionary<number>
): Dictionary<EnergySaving> => {
  const energySavings: Dictionary<EnergySaving> = {};

  const fuelPrice =
    inputUser.fuelType === FuelType.NaturalGas ? inputStatic.energyData.priceGas : inputStatic.energyData.priceCoal;
  const byproductSavings: Dictionary<ByproductSaving> = {};
  byproductSavings.fiber = byproductSaving(0.61, 0.504, inputStatic.endProducts.CGF, true, inputStatic);
  byproductSavings.germ = byproductSaving(0.52, 0.52, inputStatic.endProducts.Germ, true, inputStatic);
  byproductSavings.gluten = byproductSaving(0.6, 0.6, inputStatic.endProducts.CGM, true, inputStatic);

  energySavings.fiber = {
    beforeFrontia:
      (beforeYeild.cgf.production * inputStatic.energyData.fiberDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
    afterFrontia:
      ((afterYeild.cgf.production - extraClsDrying.tonExtraCgf) *
        (inputStatic.energyData.fiberDryer * (1 - byproductSavings.fiber.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
  };

  energySavings.germ = {
    beforeFrontia:
      (beforeYeild.germ.production * inputStatic.energyData.germDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization * inputStatic.energyData.boilerYield),
    afterFrontia:
      (afterYeild.germ.production *
        (inputStatic.energyData.germDryer * (1 - byproductSavings.germ.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization * inputStatic.energyData.boilerYield),
  };

  energySavings.gluten = {
    beforeFrontia:
      (beforeYeild.cgm.production * inputStatic.energyData.cgmDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
    afterFrontia:
      (afterYeild.cgm.production *
        (inputStatic.energyData.cgmDryer * (1 - byproductSavings.gluten.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
  };

  energySavings.total = {
    beforeFrontia:
      energySavings.fiber.beforeFrontia + energySavings.germ.beforeFrontia + energySavings.gluten.beforeFrontia,
    afterFrontia:
      energySavings.fiber.afterFrontia + energySavings.germ.afterFrontia + energySavings.gluten.afterFrontia,
  };

  return energySavings;
};

export const moreStarchConvertEtoh = (
  inputStatic: CalculateStaticData,
  afterYeild: Dictionary<YeildSaving>
): Dictionary<number> => {
  const convertEtoh: Dictionary<number> = {};

  convertEtoh.tonEtohDay = afterYeild.extraStarchTdsDay.production * inputStatic.processData.Ethanol;

  convertEtoh.revenueEthanol = convertEtoh.tonEtohDay * inputStatic.financialData.Ethanol;

  convertEtoh.costEnzyme = convertEtoh.tonEtohDay * inputStatic.ethonalData.enzymeCost;

  convertEtoh.netDayBenefit = convertEtoh.revenueEthanol - convertEtoh.costEnzyme;

  return convertEtoh;
};

export const moreStarchConvertNativeStarch = (
  afterYeild: Dictionary<YeildSaving>,
  inputStatic: CalculateStaticData
): Dictionary<number> => {
  const convertNativeStarch: Dictionary<number> = {};

  convertNativeStarch.revenueStarch =
    afterYeild.extraStarchTcoDay.production * inputStatic.nativeStarchData.nativeStarchPrice;

  convertNativeStarch.dryingCost =
    afterYeild.extraStarchTcoDay.production * inputStatic.nativeStarchData.dryingProcessCost;

  convertNativeStarch.netDayBenefit = convertNativeStarch.revenueStarch - convertNativeStarch.dryingCost;

  return convertNativeStarch;
};

export const moreStarchElectricitySavings = (
  inputUser: CalculateInput,
  beforeYeild: Dictionary<YeildSaving>,
  afterYeild: Dictionary<YeildSaving>,
  inputStatic: CalculateStaticData
): number =>
  (-(beforeYeild.corn.production - afterYeild.corn.production) *
    inputStatic.energyData.electricityRatio *
    inputStatic.energyData.priceElectricity) /
  1000;

const calculateMoreStarch = (
  inputStatic: CalculateStaticData,
  inputUser: CalculateInput,
  backgroundEnergySavings: Dictionary<number>
): MoreStarchScenario => {
  const scenario: MoreStarchScenario = {
    beforeFrontia: {},
    afterFrontia: {},
    extraClsDrying: {},
    energySavings: {},
    electricitySavings: 0,
    convertToEtoh: {},
    convertNativeStarch: {},
    moreStarch: {},
  };

  scenario.beforeFrontia = yeildBeforeFrontia(inputStatic, inputUser);

  scenario.afterFrontia = moreStarchAfterYeild(inputStatic, scenario.beforeFrontia);

  // Yearly Yeild Benefit
  scenario.moreStarch.yearlyBenefitYeild =
    (scenario.beforeFrontia.starch.revenue - scenario.afterFrontia.starch.revenue) * inputUser.daysOperating;

  scenario.extraClsDrying = computeExtraClsDrying(inputStatic, inputUser);

  scenario.energySavings = moreStarchEnergySavings(
    inputUser,
    scenario.beforeFrontia,
    scenario.afterFrontia,
    inputStatic,
    scenario.extraClsDrying
  );

  scenario.electricitySavings = moreStarchElectricitySavings(
    inputUser,
    scenario.beforeFrontia,
    scenario.afterFrontia,
    inputStatic
  );

  // Yearly benefit energy
  scenario.moreStarch.yearlyBenefitEnergy =
    inputUser.feedDryer === YesNo.yes
      ? (scenario.energySavings.total.beforeFrontia -
          scenario.energySavings.total.afterFrontia +
          scenario.electricitySavings) *
          inputUser.daysOperating -
        scenario.extraClsDrying.evaporationCost
      : (scenario.energySavings.germ.beforeFrontia +
          scenario.energySavings.gluten.beforeFrontia +
          scenario.electricitySavings -
          scenario.energySavings.germ.afterFrontia -
          scenario.energySavings.gluten.afterFrontia) *
          inputUser.daysOperating -
        scenario.extraClsDrying.evaporationCost +
        backgroundEnergySavings.savings;

  // Yearly benefit Etoh
  scenario.convertToEtoh = moreStarchConvertEtoh(inputStatic, scenario.afterFrontia);

  scenario.moreStarch.yearlyBenefitEtoh = scenario.convertToEtoh.netDayBenefit * inputUser.daysOperating;

  // Yearly benefit Native Starch
  scenario.convertNativeStarch = moreStarchConvertNativeStarch(scenario.afterFrontia, inputStatic);

  scenario.moreStarch.yearlyBenefitNativeStarch = scenario.convertNativeStarch.netDayBenefit * inputUser.daysOperating;

  scenario.moreStarch.totalGrossBenefit =
    inputUser.produceEthanol === YesNo.yes
      ? scenario.moreStarch.yearlyBenefitYeild +
        scenario.moreStarch.yearlyBenefitEnergy +
        scenario.moreStarch.yearlyBenefitEtoh
      : scenario.moreStarch.yearlyBenefitYeild +
        scenario.moreStarch.yearlyBenefitEnergy +
        scenario.moreStarch.yearlyBenefitNativeStarch;

  return scenario;
};

// Type for More starch
export type MoreStarchScenario = {
  beforeFrontia: Dictionary<YeildSaving>;
  afterFrontia: Dictionary<YeildSaving>;
  extraClsDrying: Dictionary<number>;
  energySavings: Dictionary<EnergySaving>;
  electricitySavings: number;
  convertToEtoh: Dictionary<number>;
  convertNativeStarch: Dictionary<number>;
  moreStarch: Dictionary<number>;
};

export default calculateMoreStarch;
