import React, { ReactElement, useCallback } from 'react';
import { makeStyles, TypographyProps, Typography, Tooltip, Box } from '@material-ui/core';
import { Faq } from '@novozymes-digital/components';

export type TypographyWithTooltipProps = TypographyProps & {
  tooltip: string;
};

const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  inlineBlock: {
    display: 'inline-block',
    verticalAlign: 'text-top',
    // width: 2,
    marginLeft: 0,
  },
  icon: {
    display: 'inline-block',
    verticalAlign: 'text-top',
    marginLeft: '0px;',
  },
}));

const TypographyWithTooltip = ({
  tooltip,
  className,
  children,
  ...props
}: TypographyWithTooltipProps): ReactElement => {
  const classes = useStyles();

  const mergedClasses = className ? `${className} ${classes.inlineBlock}` : classes.inlineBlock;

  const [open, setOpen] = React.useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  return (
    <Typography {...props} className={mergedClasses}>
      <>
        {children}
        <Tooltip
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          disableFocusListener
          title={<span dangerouslySetInnerHTML={{ __html: `${tooltip}` }}></span>}
          arrow
          placement="right"
          classes={{ tooltip: classes.tooltip }}
        >
          <Box mx={1} className={classes.icon} onClick={handleOpen}>
            <Faq size="small" style={{ width: '0.85rem' }} />
          </Box>
        </Tooltip>
      </>
    </Typography>
  );
};

export default TypographyWithTooltip;
