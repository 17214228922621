import { Region } from 'staticData/Region';
import { AppState } from './AppStateTypes';
import inputFields from './inputFields';
import priceInputFields from './priceInputFields';

const DefaultAppState: AppState = {
  inputFields: inputFields(Region.None),
  priceInputFields: priceInputFields(Region.NorthAmerica),
  calculateData: {
    grossbenefit: 0,
    energySavings: {
      net: 0,
      fiberDryer: 0,
      germDryer: 0,
      cgmDryer: 0,
      electricity: 0,
      cslDried: 0,
      evaporator: 0,
    },
    starchCost: {
      net: 0,
      lessCorn: 0,
      cornGlutenMeal: 0,
      cornGlutenFeed: 0,
      cornGerm: 0,
      cornSteepLiquor: 0,
    },
    higherSales: {
      net: 0,
      higherEthanolSales: 0,
      higherStarchSales: 0,
    },
    payBack: {
      capexEstimate: 0,
      netValue: 0,
      payBackTimeMonths: 0,
    },
    flexibility: {
      extraDays: 0,
      extraDaysInUSD: 0,
    },
  },
  sustainabilityData: {
    cornProcessedOutput: {
      avoidConventionalCornMilling: 0,
      frontiaJadeDosage: 0,
      inducedAnimalFeedEnergy: 0,
      steamCSLDrying: 0,
      steamSavedFiberDrying: 0,
      lowerEmissionPercentage: 0,
      total: 0,
    },
    starchProducedOutput: {
      avoidConventionalCornMilling: 0,
      frontiaJadeDosage: 0,
      inducedAnimalFeedEnergy: 0,
      steamCSLDrying: 0,
      steamSavedFiberDrying: 0,
      lowerEmissionPercentage: 0,
      total: 0,
    },
  },
};

export default DefaultAppState;
