import React, { ReactElement, useState, useCallback, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Typography, makeStyles, IconButton, Box, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { AppState } from 'appState/AppStateTypes';
import { Button, OutlinedInputWithLabel } from '@novozymes-digital/components';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

type PriceInputModalProps = {
  show: boolean;
  onClose(): void;
  appState: AppState;
  updateSeveralValues: (newValues: { attr: string; value: number }[]) => void;
};

const PriceInputModal = ({ show, onClose, appState, updateSeveralValues }: PriceInputModalProps): ReactElement => {
  const classes = useStyles();
  const [open, setOpen] = useState(show);
  const [disableCTA, setDisableCTA] = useState(false);

  const fieldSetAndGet = Object.values(appState.priceInputFields).reduce((acc, field) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    acc[field.name] = useState(field.value);
    return acc;
  }, {} as Record<string, [number, React.Dispatch<React.SetStateAction<number>>]>);

  const handleClose = useCallback((): void => {
    setOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDone = useCallback(() => {
    const newValues = Object.keys(fieldSetAndGet).map((key) => {
      const value = fieldSetAndGet[key][0];
      return { attr: key, value };
    });

    updateSeveralValues(newValues);
    setOpen(false);
    onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldSetAndGet]);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    if (open) {
      const priceUndefined = Object.values(fieldSetAndGet).some((values) => Number.isNaN(values[0]));
      setDisableCTA(priceUndefined);
    }
  }, [fieldSetAndGet, open]);

  useEffect(() => {
    Object.values(appState.priceInputFields).forEach((field) => {
      fieldSetAndGet[field.name][1](field.value);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState.priceInputFields]);

  const handleInputChange = (name: string) => (event: React.ChangeEvent<{ value: string }>) => {
    const { value } = event.target;

    if (fieldSetAndGet[name] && fieldSetAndGet[name][1]) {
      fieldSetAndGet[name][1](parseFloat(value));
    }
  };

  const { t } = useTranslation('priceDialog');

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle disableTypography={false} id="alert-dialog-title">
        <Typography variant="h2">{t('heading')}</Typography>
        <Typography variant="body1">{t('intro')}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box mt={2} mb={2}>
          <Grid container justify="center">
            {Object.values(appState.priceInputFields)
              .filter((field) => !field.hidden)
              .map((field) => (
                <Grid item xs={12} key={field.label}>
                  <Box m={1}>
                    <OutlinedInputWithLabel
                      tooltip={field.tooltip}
                      label={field.label}
                      value={fieldSetAndGet[field.name][0]}
                      adornment={field.adornment ?? ''}
                      placeholder={`e.g. ${field.default}`}
                      disabled={field.disabled}
                      handleChange={handleInputChange(field.name)}
                      decimalSeparator="."
                      formatInput
                    />
                  </Box>
                </Grid>
              ))}
          </Grid>
          <Box my={2} display="flex" justifyContent="center">
            <Button
              data-e2e="PriceInput-CloseButton"
              id="priceInput_CloseButton"
              onClick={handleDone}
              type="primary"
              aria-label="close"
              disabled={disableCTA}
            >
              {t('done')}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PriceInputModal;
