import { CalculateStaticData, CalculateInput } from 'calculation/CalculationTypes';
import endProducts from 'calculation/presetData/EndProducts';
import plantCapacityData from 'calculation/presetData/PlantCapacityInput';
import ethonalData from 'calculation/presetData/EthanolProductionInput';
import processData from 'calculation/presetData/ProcessInput';
import enzymeData from 'calculation/presetData/EnzymeData';
import getFinancialData from 'calculation/presetData/FinancialInputs';
import getNativeStarchData from 'calculation/presetData/NativeStarchInput';
import getEnergyData from './EnergyInput';
import { lcaData } from './LCAData';
import { paybackData } from './PaybackData';

const getStaticData = (input: CalculateInput): CalculateStaticData => {
  const staticData: CalculateStaticData = {
    financialData: getFinancialData(input.region),
    processData,
    endProducts,
    plantCapacityData: plantCapacityData(input),
    energyData: getEnergyData(input.region),
    ethonalData,
    nativeStarchData: getNativeStarchData(input.region),
    enzymeData,
    lcaData,
    paybackData,
  };

  return staticData;
};

export default getStaticData;
