export type ProcessData = {
  Fiber: number;
  CSL: number;
  CGM: number;
  Germ: number;
  Starch: number;
  Ethanol: number;
};

const processData: ProcessData = {
  Fiber: 0.12,
  CSL: 0.065,
  CGM: 0.045,
  Germ: 0.065,
  Starch: 0.7,
  Ethanol: 0.52,
};

export default processData;
