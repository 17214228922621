import { Region } from 'staticData/Region';
import UnitSystem from 'types/UnitSystem';

const regionUnitSystem = (region: Region): UnitSystem => {
  switch (region) {
    case Region.NorthAmerica:
      return UnitSystem.Imperial;

    case Region.Europe:
    case Region.LatinAmerica:
    case Region.MiddleEast:
    case Region.APAC:
      return UnitSystem.Metric;

    default:
      throw Error(`Unknown Region:${region}`);
  }
};

const currencySystem = (region: Region): UnitSystem => {
  switch (region) {
    case Region.NorthAmerica:
    case Region.LatinAmerica:
    case Region.APAC:
      return UnitSystem.Imperial;

    case Region.Europe:
    case Region.MiddleEast:
      return UnitSystem.Metric;

    default:
      throw Error(`Unknown Region:${region}`);
  }
};

export default class Converter {
  private readonly EURO_TO_USD: number;

  private readonly BUSHEL_TO_TONS = 0.0254;

  private readonly TONS_TO_BUSHEL = 39.368;

  private readonly GALLON_TO_METRICTON = 0.00298802721088435;

  private readonly POUNDS_TO_METRICTON = 0.000453592;

  private readonly MMBTU_TO_MWH = 0.29307107;

  private readonly BUSHEL_TO_METRICTON = 0.021772;

  /**
   *
   */
  constructor(euroToUsd: number) {
    this.EURO_TO_USD = euroToUsd;
  }

  /**
   * Will for North/Latin America convert from euro to usd
   * otherwise does not convert
   * @param to input region
   * @param currency given input currency
   */
  currency(from: Region, to: Region, currency: number): number {
    if (currencySystem(from) === currencySystem(to)) {
      return currency;
    }

    switch (to) {
      case Region.NorthAmerica:
      case Region.LatinAmerica:
      case Region.APAC:
        return currency * this.EURO_TO_USD;

      case Region.Europe:
      case Region.MiddleEast:
        return currency / this.EURO_TO_USD;

      default:
        // eslint-disable-next-line
        console.log(`Unknown Region:${to} - no conversion done`);
        return currency;
    }
  }

  /**
   * Will convert from bushel to tonnes when changing away from North America
   * Will convert from tonnes to to bushel when chaning to North America
   * conversion from corn https://www.agric.gov.ab.ca/app19/calc/crop/bushel2tonne.jsp
   * @param newRegion Region changes to
   * @param prevRegion Region changed from
   * @param weight given input for weight
   */
  weightToRegion(newRegion: Region, prevRegion: Region, weight: number): number {
    if (regionUnitSystem(newRegion) === regionUnitSystem(prevRegion)) {
      return weight;
    }

    switch (prevRegion) {
      case Region.NorthAmerica:
        return weight * this.BUSHEL_TO_TONS;

      case Region.Europe:
      case Region.LatinAmerica:
      case Region.MiddleEast:
      case Region.APAC:
        return weight * this.TONS_TO_BUSHEL;
      default:
        // eslint-disable-next-line
        console.log(`Unknown Region:${newRegion} - no conversion done`);
        return weight;
    }
  }

  gallonToMetricTon(gallon: number): number {
    return gallon / this.GALLON_TO_METRICTON;
  }

  poundToMetricTon(pounds: number): number {
    return pounds / this.POUNDS_TO_METRICTON;
  }

  mmbtuToMwh(mmbtu: number): number {
    return mmbtu / this.MMBTU_TO_MWH;
  }

  bushelToMetricTon(bushel: number): number {
    return bushel / this.BUSHEL_TO_TONS;
  }
}
