import { Region } from 'staticData/Region';

export type FinancialData = {
  Corn: number;
  CGF: number;
  CSL: number;
  CGM: number;
  Germ: number;
  Ethanol: number;
};

const europe: FinancialData = {
  Corn: 374,
  CGF: 326,
  CSL: 50,
  CGM: 1008,
  Germ: 1169.75,
  Ethanol: 0,
};

const northAmerica: FinancialData = {
  Corn: 7.9,
  CGF: 0.09,
  CSL: 0.02,
  CGM: 0.16,
  Germ: 0.44,
  Ethanol: 0,
};

const middleEast: FinancialData = {
  Corn: 374,
  CGF: 326,
  CSL: 50,
  CGM: 1008,
  Germ: 1169.75,
  Ethanol: 0,
};

const latinAmerica: FinancialData = {
  Corn: 374,
  CGF: 326,
  CSL: 50,
  CGM: 1008,
  Germ: 1169.75,
  Ethanol: 0,
};

const chinaAPAC: FinancialData = {
  Corn: 374,
  CGF: 326,
  CSL: 50,
  CGM: 1008,
  Germ: 1169.75,
  Ethanol: 0,
};

const getFinancialData = (region: Region): FinancialData => {
  switch (region) {
    case Region.Europe:
      return europe;
    case Region.LatinAmerica:
      return latinAmerica;
    case Region.MiddleEast:
      return middleEast;
    case Region.NorthAmerica:
      return northAmerica;
    case Region.APAC:
      return chinaAPAC;

    default:
      throw Error(`getFinancialData Unknown region${region}`);
  }
};

export default getFinancialData;
