import React, { ReactElement } from 'react';
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, LabelList, Cell, ResponsiveContainer } from 'recharts';
import { withTheme, makeStyles } from '@material-ui/core';
import { LCAOutput } from 'calculation/CalculationTypes';
import { formatNumberString } from 'utils/NumberHelper';
import { frontiaColors } from 'theme/colors';

type GlobalWarmingChartProps = {
  id: string;
  chartData: LCAOutput;
};

const useStyles = makeStyles((theme) => ({
  labelBar: {
    fontFamily: 'Novozymes',
    color: theme.palette.common.black,
    fontWeight: 'bold',
  },
  labelAxis: { fontFamily: 'Novozymes', color: theme.palette.common.black, fontWeight: 'bold' },
}));

const GlobalWarmingChart = ({ chartData, id }: GlobalWarmingChartProps): ReactElement => {
  const data = [
    {
      name: 'Total',
      value: formatNumberString(chartData.total, 1),
    },
    {
      name: 'Avoided conventional corn milling',
      value: formatNumberString(chartData.avoidConventionalCornMilling, 1),
    },
    {
      name: 'Steam induced for CSL drying',
      value: formatNumberString(chartData.steamCSLDrying, 1),
    },
    {
      name: 'Steam saved for fiber drying',
      value: formatNumberString(chartData.steamSavedFiberDrying, 1),
    },
    {
      name: 'Frontia® Prime dosage',
      value: formatNumberString(chartData.frontiaJadeDosage, 1),
    },
    {
      name: 'Induced animal feed energy',
      value: formatNumberString(chartData.inducedAnimalFeedEnergy, 1),
    },
  ];

  const allValues = data.map((o) => {
    return parseFloat(o.value);
  });

  const xMaxValue = Math.max(...allValues);

  const xMinValue = Math.min(...allValues);

  const classes = useStyles();
  return (
    <ResponsiveContainer height={400} width={800} id={id}>
      <ComposedChart
        layout="vertical"
        data={data}
        margin={{
          top: 20,
          right: 100,
          bottom: 20,
          left: 25,
        }}
      >
        <CartesianGrid stroke="#f5f5f5" horizontal={false} />
        <XAxis
          type="number"
          padding={{ left: 50, right: 50 }}
          domain={[xMinValue, xMaxValue]}
          className={classes.labelAxis}
        />
        <YAxis
          dataKey="name"
          type="category"
          scale="band"
          width={300}
          height={100}
          style={{ fontFamily: 'Novozymes, Arial' }}
          className={classes.labelAxis}
        />
        <Bar dataKey="value" barSize={35} isAnimationActive={false}>
          <LabelList dataKey="value" position="right" className={classes.labelBar} />
          {data.map((entry) => (
            <Cell
              fill={entry.name === 'Total' ? frontiaColors.primaryGreen : frontiaColors.grayBlue}
              key={entry.name}
            />
          ))}
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default withTheme(GlobalWarmingChart);
