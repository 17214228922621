import { InputOption } from 'appState/AppStateTypes';

export enum Region {
  Europe,
  NorthAmerica,
  MiddleEast,
  LatinAmerica,
  APAC,
  None = NaN,
}

const locations: InputOption[] = [
  {
    label: 'Europe',
    value: Region.Europe,
  },
  {
    label: 'North America',
    value: Region.NorthAmerica,
  },
  {
    label: 'Middle-East',
    value: Region.MiddleEast,
  },
  {
    label: 'Latin America',
    value: Region.LatinAmerica,
  },
  {
    label: 'APAC',
    value: Region.APAC,
  },
];

export default locations;
