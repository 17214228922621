export type YeildRecovery = {
  maxRecovery: number;
  recovered: number;
  recovery: number;
};

export type EnzymeData = {
  starch: YeildRecovery;
  gluten: YeildRecovery;
  cgfDryer: number;
  germDryer: number;
  cgmDryer: number;
};

const enzymeData: EnzymeData = {
  starch: {
    maxRecovery: 0.012,
    recovered: 0.012 * 1,
    recovery: 1,
  },
  gluten: {
    maxRecovery: 0.0025,
    recovered: 0.0025 * 1,
    recovery: 1,
  },
  //cgfDryer: (((1 / (1 - 0.61) - 1 / (1 - 0.56)) / (1 / (1 - 0.61) - 1 / (1 - 0.12))) * 0.12) / (0.12 + 0.065),
  // germDryer: 0.08,
  // cgmDryer: (1 / (1 - 0.6) - 1 / (1 - 0.58)) / (1 / (1 - 0.6) - 1 / (1 - 0.08)),
  //below values are dynalically calculated
  cgfDryer: 1,
  germDryer: 1,
  cgmDryer: 1,
};

export default enzymeData;
