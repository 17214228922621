import {
  CalculateInput,
  CalculateOutput,
  CalculateStaticData,
  CalculatePriceInput,
} from 'calculation/CalculationTypes';
import calculateLessCornMilling from 'calculation/LessCornMillingScenario';
import calculateEnergy from 'calculation/EnergyCalculations';
import calculateMoreStarch from 'calculation/MoreStarchScenario';
import getStaticData from 'calculation/presetData/PresetDataHelper';
import FinalCalculation from 'calculation/FinalCalculation';
import Converter from 'utils/Converter';
import transformUserInput, { transformPricingData } from './comon/UserInputTransform';

const calculateData = (
  input: CalculateInput,
  priceInput: CalculatePriceInput,
  converter: Converter
): CalculateOutput => {
  const transformedInput = transformUserInput(input, converter);

  const { NativeStarch, ...additionalPrices } = priceInput;
  const originalStaticData = getStaticData(transformedInput);
  originalStaticData.financialData = {
    ...originalStaticData.financialData,
    ...additionalPrices,
  };
  originalStaticData.nativeStarchData.nativeStarchPrice = NativeStarch;

  const staticData: CalculateStaticData = transformPricingData(originalStaticData, transformedInput.region, converter);

  const backgroundEnergySavings = calculateEnergy(staticData, transformedInput);

  const lessCornMilling = calculateLessCornMilling(staticData, transformedInput, backgroundEnergySavings);

  const moreStarch = calculateMoreStarch(staticData, transformedInput, backgroundEnergySavings);

  const finalCalculations = FinalCalculation.finalCalculation(
    lessCornMilling,
    moreStarch,
    transformedInput,
    backgroundEnergySavings
  );

  // Seems like there is a wrong reference with Q35 and Q37
  // finalCalculations.benefitsStarch.net+finalCalculations.benefitsEnergy.net+finalCalculations.benefitsSales.net+
  //   ((lessCornMilling.afterFrontia.corn.production*(mapedInput.daysOperating-mapedInput.daysFullCapacity)
  //   *'Background - calculation'!Q35*'Background - calculation'!Q37)+('Background - calculation'!Z55*'Background
  // - calculation'!Q35*'Background
  // - calculation'!Q37*'Background - final calculation'!F3)),
  const output: CalculateOutput = {
    grossbenefit:
      //   finalCalculations.benefitsStarch.net + finalCalculations.benefitsEnergy.net + finalCalculations.benefitsSales.net,
      finalCalculations.benefitsStarch.net +
      finalCalculations.benefitsEnergy.net +
      finalCalculations.benefitsSales.net +
      (lessCornMilling.afterFrontia.corn.production * (input.daysOperating - input.daysFullCapacity) * 0 * 0 +
        moreStarch.afterFrontia.corn.production * 0 * 0 * input.daysOperating * input.daysFullCapacity),
    energySavings: {
      net: finalCalculations.benefitsEnergy.net,
      fiberDryer: finalCalculations.benefitsEnergy.lessFiberDryer,
      germDryer: finalCalculations.benefitsEnergy.lessGermDryer,
      cgmDryer: finalCalculations.benefitsEnergy.lessCgmDryer,
      electricity: finalCalculations.benefitsEnergy.lessElectricity,
      cslDried: finalCalculations.benefitsEnergy.moreClsDried,
      evaporator: finalCalculations.benefitsEnergy.lessEvaporator,
    },
    starchCost: {
      net: finalCalculations.benefitsStarch.net,
      lessCorn: finalCalculations.benefitsStarch.lessCorn,
      cornGlutenMeal: finalCalculations.benefitsStarch.cornGlutenMeal,
      cornGlutenFeed: finalCalculations.benefitsStarch.cornGlutenFeed,
      cornGerm: finalCalculations.benefitsStarch.cornGerm,
      cornSteepLiquor: finalCalculations.benefitsStarch.cornSteepLiquor,
    },
    higherSales: {
      net: finalCalculations.benefitsSales.net,
      higherEthanolSales: finalCalculations.benefitsSales.ethonalSales,
      higherStarchSales: finalCalculations.benefitsSales.starchSales,
    },
    payBack: {
      capexEstimate: finalCalculations.benefitsPayBack.capexEstimate,
      netValue: finalCalculations.benefitsPayBack.capexEstimate,
      payBackTimeMonths: finalCalculations.benefitsPayBack.payBackTimeMonths,
    },
    flexibility: {
      extraDays: finalCalculations.benefitsFlexibility.extraDays,
      extraDaysInUSD: finalCalculations.benefitsFlexibility.extraDaysInUSD,
    },
  };

  return output;
};

export default calculateData;
