/* eslint-disable react/destructuring-assignment */
import React, { ReactElement } from 'react';
import { Button, makeStyles, ButtonProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: ({ fontColor }: { fontColor: string | undefined }) => ({
    borderRadius: '22px',
    color: fontColor || theme.palette.text.primary,
    letterSpacing: '0rem',
    textTransform: 'none',
    fontSize: '1rem',
    alignSelf: 'center',
  }),
}));

const CtaButton = ({ fontColor, ...props }: ButtonProps & { fontColor?: string }): ReactElement => {
  const classes = useStyles({ fontColor });
  return (
    <Button classes={classes} disableElevation {...props}>
      {props.children}
    </Button>
  );
};

export default CtaButton;
