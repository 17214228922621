import { YesNo } from 'appState/AppStateTypes';
import { FuelType } from 'staticData/Fuel';
import { Dictionary } from 'types/Dictionary';
import { roundPrecision } from 'utils/NumberHelper';
import { CalculateStaticData, CalculateInput, LCAOutput } from './CalculationTypes';
import {
  conventionalFactorReference,
  conventionalInputReference,
  conventionalOutputReference,
} from './presetData/LCAData';
import plantCapacityData from './presetData/PlantCapacityInput';

type CalculateInputs = {
  steamMoistureFibers: number;
  steamMoistureCSL: number;
  starchInFibers: number;
  starchOutput: number;
  starchYield: number;
};

type FrontiaJadeTrialInputs = {
  fiberMoisture: number;
  totalStarchInFiber: number;
  cslSprayOnFiber: number;
  frontiaJadeDosage: number;
};

// Calculate Conventional Inputs
const getCalculatedConventionalInputs = (staticData: CalculateStaticData): CalculateInputs => {
  const {
    moistureCommercialCorn,
    fiberYield,
    fiberDryerEfficiency,
    moistureCornSteepLiquor,
    moistureStarch,
    yieldConventional,
    fiberMoisture,
    cslSprayOnFiber,
    totalStarchInFiber,
  } = staticData.lcaData;

  const steamMoistureFibers =
    (fiberDryerEfficiency * 1000 * (1 - moistureCommercialCorn) * fiberYield * fiberMoisture) / (100 - fiberMoisture);

  const steamMoistureCSL =
    fiberDryerEfficiency *
    cslSprayOnFiber *
    (moistureCornSteepLiquor / (1 - moistureCornSteepLiquor) - (1 - 0.9) / 0.9);

  const starchInFibers = fiberYield * 1000 * (1 - moistureCommercialCorn) * (totalStarchInFiber / 100);

  const starchOutput = yieldConventional * (1 - moistureStarch) * 1000;

  const starchYield = starchOutput / (1000 * (1 - moistureCommercialCorn));

  const conventionalInputs: CalculateInputs = {
    steamMoistureFibers,
    steamMoistureCSL,
    starchInFibers,
    starchOutput,
    starchYield,
  };
  return conventionalInputs;
};

// Calculate Frontia Jade Calculated Inputs
const getCalculatedFrontiaJadeInputs = (
  staticData: CalculateStaticData,
  frontiaJadeTrialInputs: FrontiaJadeTrialInputs,
  conventionalStarchOutput: number,
  conventionStarchInFibers: number
): CalculateInputs => {
  const { moistureCommercialCorn, fiberYield, fiberDryerEfficiency, moistureCornSteepLiquor } = staticData.lcaData;
  const { fiberMoisture, cslSprayOnFiber, totalStarchInFiber } = frontiaJadeTrialInputs;

  const steamMoistureFibers =
    (fiberDryerEfficiency * 1000 * (1 - moistureCommercialCorn) * fiberYield * fiberMoisture) / (100 - fiberMoisture);

  const steamMoistureCSL =
    fiberDryerEfficiency *
    cslSprayOnFiber *
    (moistureCornSteepLiquor / (1 - moistureCornSteepLiquor) - (1 - 0.9) / 0.9);

  const starchInFibers = fiberYield * 1000 * (1 - moistureCommercialCorn) * (totalStarchInFiber / 100);

  const starchOutput = conventionalStarchOutput - (starchInFibers - conventionStarchInFibers);

  const starchYield = starchOutput / (1000 * (1 - moistureCommercialCorn));

  const conventionalInputs: CalculateInputs = {
    steamMoistureFibers,
    steamMoistureCSL,
    starchInFibers,
    starchOutput,
    starchYield,
  };
  return conventionalInputs;
};

// calculate %improvement when using Frontia Jade
const getFrontiaJadeAssumptionInputs = (): FrontiaJadeTrialInputs => {
  const frontiaJadeInputData: FrontiaJadeTrialInputs = {
    fiberMoisture: 53.5,
    totalStarchInFiber: 14.17,
    cslSprayOnFiber: 34.82,
    frontiaJadeDosage: 0.2,
  };

  return frontiaJadeInputData;
};

// LCA CornProcessed Output Function
const calculateCornProcessedLCA = (
  conventionalInputs: CalculateInputs,
  frontiaJadeInputs: CalculateInputs,
  fuelSource: number,
  lcaReferemce: number
): LCAOutput => {
  // const starchInFibersDiff =  frontiaJadeInputs.starchInFibers - conventionalInputs.starchInFibers;
  // const starchYieldDiff =  frontiaJadeInputs.starchYield - conventionalInputs.starchYield;
  const starchOutputDiff = roundPrecision(frontiaJadeInputs.starchOutput - conventionalInputs.starchOutput, 2);
  const steamMoistureCSLDiff = roundPrecision(
    frontiaJadeInputs.steamMoistureCSL - conventionalInputs.steamMoistureCSL,
    2
  );
  const steamMoistureFibersDiff = roundPrecision(
    frontiaJadeInputs.steamMoistureFibers - conventionalInputs.steamMoistureFibers,
    2
  );

  const steamSavedFiberDrying = roundPrecision(steamMoistureFibersDiff * fuelSource, 2);
  const steamCSLDrying = roundPrecision(steamMoistureCSLDiff * fuelSource, 2);
  const avoidConventionalCornMilling = roundPrecision(starchOutputDiff * -lcaReferemce, 2);

  const inducedAnimalFeedEnergy = roundPrecision(starchOutputDiff * 15.4 * 0.0464722139441748, 2);
  const frontiaJadeDosage = roundPrecision(0.2 * 4.35279702589712, 3);

  //For the Marketing tool we now have a static value instead of this calculation
  // const total = roundPrecision(
  //   steamSavedFiberDrying + steamCSLDrying + avoidConventionalCornMilling + inducedAnimalFeedEnergy + frontiaJadeDosage,
  //   2
  // );
  const total = -12;

  const output: LCAOutput = {
    steamSavedFiberDrying,
    steamCSLDrying,
    avoidConventionalCornMilling,
    inducedAnimalFeedEnergy,
    frontiaJadeDosage,
    lowerEmissionPercentage: 0,
    total,
  };

  return output;
};

// LCA Starch Produced Output Function
const calculateStarchProducedLCA = (cornProcessedOutput: LCAOutput, starchOutput: number): LCAOutput => {
  const steamSavedFiberDrying = roundPrecision((cornProcessedOutput.steamSavedFiberDrying * 1000) / starchOutput, 2);
  const steamCSLDrying = roundPrecision((cornProcessedOutput.steamCSLDrying * 1000) / starchOutput, 2);
  const avoidConventionalCornMilling = roundPrecision(
    (cornProcessedOutput.avoidConventionalCornMilling * 1000) / starchOutput,
    2
  );
  const inducedAnimalFeedEnergy = roundPrecision(
    (cornProcessedOutput.inducedAnimalFeedEnergy * 1000) / starchOutput,
    2
  );
  const frontiaJadeDosage = roundPrecision((cornProcessedOutput.frontiaJadeDosage * 1000) / starchOutput, 2);

  //For the Marketing tool we now have a static value instead of this calculation
  //const total = roundPrecision((cornProcessedOutput.total * 1000) / starchOutput, 2);
  const total = 12;

  const output: LCAOutput = {
    steamSavedFiberDrying,
    steamCSLDrying,
    avoidConventionalCornMilling,
    inducedAnimalFeedEnergy,
    frontiaJadeDosage,
    lowerEmissionPercentage: 0,
    total,
  };

  return output;
};

const getFuelSource = (fuelType: FuelType): number => {
  const coal = 0.525330498636552;
  const naturalGas = 0.182582659826434;

  return fuelType === FuelType.Coal ? coal : naturalGas;
};

const getFateOfCSL = (sellSteepLiqour: YesNo, fuelType: FuelType): number => {
  if (sellSteepLiqour === YesNo.no) return fuelType === FuelType.Coal ? 1.09311194665788 : 0.379919473982277;

  return 0.881889183987857;
};

// Life cycle assessment of conventional corn milling (reference)
const getCalculatedLcaReference = (fuelSource: number, fateOfCSL: number): number => {
  // Inputs
  const capitalGoods = roundPrecision(
    conventionalInputReference.capitalGoods * conventionalFactorReference.capitalGoods,
    1
  );
  const commercialCorn = roundPrecision(
    conventionalInputReference.commercialCorn * conventionalFactorReference.commercialCorn,
    1
  );
  const electricity = roundPrecision(
    conventionalInputReference.electricity * conventionalFactorReference.electricity,
    1
  );
  const steam = roundPrecision(conventionalInputReference.steam * fuelSource, 1);
  const tapWater = roundPrecision(conventionalInputReference.tapWater * conventionalFactorReference.tapWater, 1);
  const wastewater = roundPrecision(conventionalInputReference.wastewater * conventionalFactorReference.wastewater, 1);

  // Outputs
  const CGF = roundPrecision(conventionalOutputReference.CGF * conventionalFactorReference.CGF, 1);
  const CGM = roundPrecision(conventionalOutputReference.CGM * conventionalFactorReference.CGM, 1);
  const CSL = roundPrecision(conventionalOutputReference.CSL * fateOfCSL, 1);
  const cornGerm = roundPrecision(conventionalOutputReference.cornGerm * conventionalFactorReference.cornGerm, 1);

  const sum = roundPrecision(
    capitalGoods + commercialCorn + electricity + steam + tapWater + wastewater + CGF + CGM + CSL + cornGerm,
    0
  );

  return sum;
};

const calculateSustainability = (staticData: CalculateStaticData, inputData: CalculateInput): Dictionary<LCAOutput> => {
  const sustainabilityCalculations: Dictionary<LCAOutput> = {};

  // calculate %improvement when using Frontia Jade
  const frontiaJadeInputData = getFrontiaJadeAssumptionInputs();

  // Calculate Conventional and Frontia Jade Calculated Inputs
  const conventionalInputs = getCalculatedConventionalInputs(staticData);

  const frontiaJadeInputs = getCalculatedFrontiaJadeInputs(
    staticData,
    frontiaJadeInputData,
    conventionalInputs.starchOutput,
    conventionalInputs.starchInFibers
  );

  const fuelSource = getFuelSource(inputData.fuelType);

  const fateOfCSL = getFateOfCSL(inputData.sellSteepLiqour, inputData.fuelType);

  const lcaMetricReferemce = getCalculatedLcaReference(fuelSource, fateOfCSL);
  const lcaReferemce = roundPrecision(lcaMetricReferemce / 602, 3);

  sustainabilityCalculations.cornProcessedOutput = calculateCornProcessedLCA(
    conventionalInputs,
    frontiaJadeInputs,
    fuelSource,
    lcaReferemce
  );
  /*Changes 2022*/
  // const lowerEmissionPercentage = roundPrecision(
  //   (sustainabilityCalculations.cornProcessedOutput.total * inputData.daysOperating * inputData.totalCapcity) / 1000,
  //   0
  // );

  const co2Saved = 12;
  const plantCapacity = plantCapacityData(inputData);
  const lowerEmissionPercentage = roundPrecision(
    (co2Saved * plantCapacity.millingRate * inputData.daysOperating) / 1000,
    2
  );

  sustainabilityCalculations.cornProcessedOutput.lowerEmissionPercentage = lowerEmissionPercentage;

  sustainabilityCalculations.starchProducedOutput = calculateStarchProducedLCA(
    sustainabilityCalculations.cornProcessedOutput,
    frontiaJadeInputs.starchOutput
  );

  return sustainabilityCalculations;
};

export default calculateSustainability;
