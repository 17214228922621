import { Region } from 'staticData/Region';

const roundValue = (value: number): number => Math.round((value + Number.EPSILON) * 100) / 100;

export const formatNumberString = (value: number, decimals = 0): string =>
  new Intl.NumberFormat('en-US').format(Math.round(value * 10 ** decimals) / 10 ** decimals);

export const roundPrecision = (value: number, precision: number): number => {
  const multiplier = 10 ** (precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const exactNumberToString = (value: number): string => {
  const formater = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 1,
    maximumFractionDigits: 1,
  } as Intl.NumberFormatOptions);
  return formater.format(value);
};

export const numberToStringCompact = (value: number): string => {
  const formater = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
  } as Intl.NumberFormatOptions);
  return formater.format(Math.round(value));
};

export const numberToStringPercent = (value: number): string => value.toFixed(1);

export const stringToNumber = (value: string): number => parseFloat(value.replace(/,/g, ''));

export const formatCurrency = (currency: number, region: Region, decimals?: number): string =>
  `${
    region === Region.NorthAmerica || region === Region.LatinAmerica || region === Region.APAC ? '$' : '€'
  } ${formatNumberString(currency, decimals)}`;

export default roundValue;
