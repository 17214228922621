import pardotPostData from 'utils/pardotPostData';
import { Region } from 'staticData/Region';
import CookieHelper from 'utils/CookieHelper';
import { AppState, YesNo } from './AppStateTypes';

const PARDOT_FORM_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-06-16/c688b';

export default (appState: AppState): void => {
  const userData = CookieHelper.getInstance().get('user');

  const pardotData = {
    region: Region[appState.inputFields.region.value],
    daysOperating: appState.inputFields.daysOperating.value,
    daysFullCapacity: appState.inputFields.daysFullCapacity.value,
    totalCapcity: appState.inputFields.totalCapcity.value,
    produceEthanol: YesNo[appState.inputFields.produceEthanol.value],
    feedDryer: YesNo[appState.inputFields.feedDryer.value],
    sellSteepLiqour: YesNo[appState.inputFields.sellSteepLiqour.value],
    excessCornSteepLiqour: appState.inputFields.excessCornSteepLiqour.value,
    priceInput: {
      Corn: appState.priceInputFields.Corn.value,
      CGF: appState.priceInputFields.CGF.value,
      CSL: appState.priceInputFields.CSL.value,
      CGM: appState.priceInputFields.CGM.value,
      Germ: appState.priceInputFields.Germ.value,
      Ethanol: appState.priceInputFields.Ethanol.value,
      NativeStarch: appState.priceInputFields.NativeStarch.value,
    },
  };

  const pardotFields = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(userData as Record<string, any>),
    pardot_calculator_values: JSON.stringify(pardotData),
  };
  pardotPostData(PARDOT_FORM_ENDPOINT, pardotFields);
};
