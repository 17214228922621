import { InputOption } from 'appState/AppStateTypes';

export enum FuelType {
  NaturalGas,
  Coal,
}

const fuelOptions: InputOption[] = [
  {
    label: 'Natural Gas',
    value: FuelType.NaturalGas,
  },
  {
    label: 'Coal',
    value: FuelType.Coal,
  },
];

export default fuelOptions;
