import React, { ReactElement } from 'react';
import { CalculateOutput } from 'calculation/CalculationTypes';
import { useTranslation } from 'react-i18next';
import WaterfallChart, { BarType } from './WaterfallChart';

type IncreasedYieldChartProps = {
  id: string;
  height: number | string;
  width: number | string;
  chartDataStarch: CalculateOutput['starchCost'];
  chartDataSales: CalculateOutput['higherSales'];
};

const IncreasedYieldChart = ({
  id,
  height,
  width,
  chartDataStarch,
  chartDataSales,
}: IncreasedYieldChartProps): ReactElement => {
  const { t } = useTranslation('increasedYieldChart');

  const bars: BarType[] = [
    {
      dataKey: 'lessCorn',
      dataNameKey: 'lessCornTitle',
      dataHiddenKey: 'lessCornHidden',
      highlighted: false,
      value: chartDataStarch.lessCorn,
      title: t('saveCorn'),
    },
    {
      dataKey: 'cornGlutenFeed',
      dataNameKey: 'cornGlutenFeedTitle',
      dataHiddenKey: 'cornGlutenFeedHidden',
      highlighted: false,
      value: chartDataStarch.cornGlutenFeed,
      title: t('cornGlutenFeed'),
    },
    {
      dataKey: 'higherEthanolSales',
      dataNameKey: 'higherEthanolSalesTitle',
      dataHiddenKey: 'higherEthanolSalesHidden',
      highlighted: false,
      value: chartDataSales.higherEthanolSales,
      title: t('ethanolSales'),
    },
    {
      dataKey: 'higherStarchSales',
      dataNameKey: 'higherStarchSalesTitle',
      dataHiddenKey: 'higherStarchSalesHidden',
      highlighted: false,
      value: chartDataSales.higherStarchSales,
      title: t('starchSales'),
    },
    {
      dataKey: 'cornGlutenMeal',
      dataNameKey: 'cornGlutenMealTitle',
      dataHiddenKey: 'cornGlutenMealHidden',
      highlighted: false,
      value: chartDataStarch.cornGlutenMeal,
      title: t('cornGlutenMeal'),
    },
    {
      dataKey: 'cornGerm',
      dataNameKey: 'cornGermTitle',
      dataHiddenKey: 'cornGermHidden',
      highlighted: false,
      value: chartDataStarch.cornGerm,
      title: t('cornGerm'),
    },
    {
      dataKey: 'cornSteepLiquor',
      dataNameKey: 'cornSteepLiquorTitle',
      dataHiddenKey: 'cornSteepLiquorHidden',
      highlighted: false,
      value: chartDataStarch.cornSteepLiquor,
      title: t('cornSteepLiquor'),
    },
    {
      dataKey: 'total',
      dataNameKey: 'totalTitle',
      highlighted: true,
      value: chartDataStarch.net + chartDataSales.net,
      title: t('total'),
    },
  ];

  return <WaterfallChart id={id} height={height} width={width} bars={bars} />;
};

export default IncreasedYieldChart;
