import { CalculateInput, CalculateStaticData } from 'calculation/CalculationTypes';
import { Region } from 'staticData/Region';
import Converter from 'utils/Converter';
import { YesNo } from 'appState/AppStateTypes';
import { cloneDeep } from 'lodash';

const transformUserInput = (input: CalculateInput, converter: Converter): CalculateInput => {
  const transformedInput = { ...input };

  /* eslint-disable */
  const totalCapcity = converter.weightToRegion(Region.Europe, input.region, transformedInput.totalCapcity);
  /* eslint-enable */

  transformedInput.excessCornSteepLiqour =
    input.sellSteepLiqour === YesNo.no ? 0 : transformedInput.excessCornSteepLiqour;
  //transformedInput.excessCornSteepLiqour = (100 - transformedInput.excessCornSteepLiqour) / 100;
  transformedInput.excessCornSteepLiqour =
    input.sellSteepLiqour === YesNo.no
      ? (100 - transformedInput.excessCornSteepLiqour) / 100
      : 1 - transformedInput.excessCornSteepLiqour / 100;
  return transformedInput;
};

export const transformPricingData = (
  pricingData: CalculateStaticData,
  region: Region,
  converter: Converter
): CalculateStaticData => {
  const clone = cloneDeep(pricingData);

  switch (region) {
    case Region.Europe:
    case Region.MiddleEast:
      return clone;
    case Region.NorthAmerica:
      // Bushel to MT
      clone.financialData.Corn = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.bushelToMetricTon(clone.financialData.Corn)
      );

      // Pounds to MT
      clone.financialData.CGF = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.poundToMetricTon(clone.financialData.CGF)
      );

      clone.financialData.CSL = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.poundToMetricTon(clone.financialData.CSL)
      );

      clone.financialData.CGM = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.poundToMetricTon(clone.financialData.CGM)
      );

      clone.financialData.Germ = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.poundToMetricTon(clone.financialData.Germ)
      );

      clone.nativeStarchData.nativeStarchPrice = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        clone.nativeStarchData.nativeStarchPrice
      );

      // Gallon to MT
      clone.financialData.Ethanol = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.gallonToMetricTon(clone.financialData.Ethanol)
      );

      // GAS and EL transform
      clone.energyData.priceGas = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        converter.mmbtuToMwh(clone.energyData.priceGas)
      );

      clone.energyData.priceElectricity = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        clone.energyData.priceElectricity
      );

      return clone;

    case Region.LatinAmerica:
      // USD to Euro
      clone.financialData.Corn = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.Corn);

      clone.financialData.CGF = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.CGF);

      clone.financialData.CSL = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.CSL);

      clone.financialData.CGM = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.CGM);

      clone.financialData.Germ = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.Germ);

      clone.nativeStarchData.nativeStarchPrice = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        clone.nativeStarchData.nativeStarchPrice
      );

      clone.financialData.Ethanol = converter.currency(Region.NorthAmerica, Region.Europe, clone.financialData.Ethanol);

      // GAS and EL transform
      clone.energyData.priceGas = converter.currency(Region.NorthAmerica, Region.Europe, clone.energyData.priceGas);

      clone.energyData.priceElectricity = converter.currency(
        Region.NorthAmerica,
        Region.Europe,
        clone.energyData.priceElectricity
      );
      return clone;
    default:
      return clone;
  }
};

export default transformUserInput;
