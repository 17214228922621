import React, { ReactElement } from 'react';
import { CalculateOutput } from 'calculation/CalculationTypes';
import { useTranslation } from 'react-i18next';
import WaterfallChart from './WaterfallChart';

type EnergySavingsChartProps = {
  id: string;
  height: number | string;
  width: number | string;
  chartData: CalculateOutput['energySavings'];
};

const EnergySavingsChart = ({ id, height, width, chartData }: EnergySavingsChartProps): ReactElement => {
  const { t } = useTranslation('energySavingsChart');
  const bars = [
    {
      dataKey: 'fiberDryer',
      dataNameKey: 'fiberDryerTitle',
      dataHiddenKey: 'fiberDryerHidden',
      highlighted: false,
      title: t('fiberDryer'),
      value: chartData.fiberDryer,
    },
    {
      dataKey: 'germDryer',
      dataNameKey: 'germDryerTitle',
      dataHiddenKey: 'germDryerHidden',
      highlighted: false,
      title: t('germDryer'),
      value: chartData.germDryer,
    },
    {
      dataKey: 'cgmDryer',
      dataNameKey: 'cgmDryerTitle',
      dataHiddenKey: 'cgmDryerHidden',
      highlighted: false,
      title: t('CGMDryer'),
      value: chartData.cgmDryer,
    },
    {
      dataKey: 'cslDried',
      dataNameKey: 'cslDriedTitle',
      dataHiddenKey: 'cslDriedHidden',
      highlighted: false,
      title: t('CSLDried'),
      value: chartData.cslDried,
    },
    {
      dataKey: 'electricity',
      dataNameKey: 'electricityTitle',
      dataHiddenKey: 'electricityHidden',
      highlighted: false,
      title: t('electricity'),
      value: chartData.electricity,
    },

    {
      dataKey: 'evaporator',
      dataNameKey: 'evaporatorTitle',
      dataHiddenKey: 'evaporatorHidden',
      highlighted: false,
      title: t('evaporator'),
      value: chartData.evaporator,
    },
    {
      dataKey: 'total',
      dataNameKey: 'totalTitle',
      highlighted: true,
      title: t('total'),
      value: chartData.net,
    },
  ];

  return <WaterfallChart id={id} height={height} width={width} bars={bars} />;
};

export default EnergySavingsChart;
