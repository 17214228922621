import React, { ReactElement, ReactNode, useState, useCallback } from 'react';
import { Typography, Box, makeStyles, Grid } from '@material-ui/core';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Message } from '@novozymes-digital/components';
import CtaButton from 'components/CtaButton';
import ContactFormDialog from 'components/dialogs/ContactFormDialog';
import { AppState } from 'appState/AppStateTypes';
import { useTranslation } from 'react-i18next';
import { useIsSmall } from '../utils/MediaQueryHelper';
import typography from 'theme/typography';

const useStyles = makeStyles((theme) => ({
  container: {
    background: theme.palette.secondary.main,
    height: '80px',
    width: '100vw',
    [theme.breakpoints.down(1280)]: {
      zIndex: 3,
      position: 'fixed',
      height: '65px',
    },
    [theme.breakpoints.down(600)]: {
      zIndex: 3,
      position: 'fixed',
      height: '50px',
    },
  },
  logo: {
    color: theme.palette.common.white,
    width: '80px',
    [theme.breakpoints.down('md')]: {
      width: '60px',
    },
  },
  title: {
    ...typography.hb6,
    color: '#FFF',
    [theme.breakpoints.down(1280)]: {
      ...typography.hb8,
    },
    [theme.breakpoints.down(600)]: {
      textAlign: 'center',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  inlineBlock: {
    display: 'inline-block',
  },
  floatRight: {
    float: 'right',
  },
  whiteFont: {
    color: theme.palette.common.white,
    fontWeight: 400,
  },
  cta: {
    backgroundColor: 'transparent',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.action.hover,
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(8),
    right: theme.spacing(3),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  msg: {
    color: 'rgb(0,0,0,0.8)',
  },
  hb6: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
    color: theme.palette.common.white,
  },
  hb8: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
    color: theme.palette.common.white,
  },
  messageIcon: {
    transform: 'scale(0.8)',
    cursor: 'pointer',
    [theme.breakpoints.down('md')]: {
      transform: 'scale(0.7)',
    },
  },
}));

interface HeaderProps extends RouteComponentProps {
  children?: ReactNode;
  appState: AppState;
}

const Header = ({ history, appState }: HeaderProps): ReactElement => {
  const classes = useStyles();
  const isSmall = useIsSmall();
  const [showContactForm, setShowContactForm] = useState(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goHome = useCallback((): void => history.push('/'), []);
  const onClose = useCallback(() => setShowContactForm(false), []);
  const onContactClick = useCallback(() => setShowContactForm(true), []);

  const { t } = useTranslation('header');

  return (
    <>
      <ContactFormDialog show={showContactForm} onClose={onClose} appState={appState} />

      <Box className={classes.container}>
        {!isSmall ? (
          <Box mx={10}>
            <Grid container alignItems="center" justifyContent="space-between" style={{ height: '80px' }}>
              <Grid item xs={6} container direction="column">
                <Grid item className={`${classes.pointer}`} onClick={goHome}>
                  <img id="logo" className={classes.logo} src="/logo.png" alt="Novozymes Logo" />
                </Grid>

                <Grid component={Typography} className={classes.title} variant="h6" item>
                  <span dangerouslySetInnerHTML={{ __html: `${t('title')}` }}></span>
                </Grid>
              </Grid>

              <Grid item container justify="flex-end" xs={6}>
                <CtaButton
                  data-e2e="header-ContactUsCTA"
                  id="header_ContactUs_CTA"
                  variant="contained"
                  color="primary"
                  className={classes.cta}
                  onClick={onContactClick}
                >
                  {t('contactUs')}
                </CtaButton>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box mx={2}>
            <Grid container alignItems="center" style={{ height: '50px' }}>
              <Grid item className={`${classes.pointer}`} onClick={goHome} xs={3}>
                <img id="logo" className={classes.logo} src="/logo.png" alt="Novozymes Logo" />
              </Grid>

              <Grid component={Typography} className={classes.title} variant="h6" xs={6} item>
                <span dangerouslySetInnerHTML={{ __html: `${t('title')}` }}></span>
              </Grid>

              <Grid item xs={3} container justify="flex-end">
                <CtaButton
                  data-e2e="header-ContactUsCTA"
                  id="header_ContactUs_CTA"
                  variant="contained"
                  color="primary"
                  className={classes.cta}
                  onClick={onContactClick}
                >
                  <Message className={classes.messageIcon} />
                </CtaButton>
              </Grid>
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

export default withRouter(Header);
