/* eslint-disable max-len */
/* eslint-disable no-tabs */
import React, { ReactElement } from 'react';
import { Typography, Box, Link, BoxProps } from '@material-ui/core';

const TermsConditions = (props: BoxProps): ReactElement => (
  <>
    <Box m={4} mt={4} {...props}>
      <Box m={2}>
        <Typography variant="h1">LEGAL NOTICE/TERMS OF USE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          Please read this legal notice and the privacy policy carefully before using this website. This website,
          https://grainmilling.novozymes.cloud (“the Website”), is owned and managed by Novozymes A/S (“Novozymes”). By
          using the Website, you expressly accept that your use will be subject to the terms and conditions described in
          this Legal notice and in the <Link href="/privacy">Privacy policy</Link>. Further, you acknowledge that
          Novozymes may at any time change these terms and conditions. You should review the legal notice each time you
          visit the website.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">PURPOSE OF THE WEBSITE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The purpose of the website is strictly commercial, and as such, it is meant to further Novozymes’ business by
          the sales of enzymes and microbial products to water utilities and partners. This is done by calculating
          improve yield, lower energy consumption and improve mill flexibility by applying Frontia® Prime, a Novozymes
          solution. The calculations are partly based on input from the user and partly from Novozymes assumptions. The
          indications provided by this calculator are purely indicative, and are only meant as guidance, which should
          not replace actual full-scale trials. Contact Novozymes for further advice involving enzymes and microbes,
          before conducting physical trials.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If you have any questions or comments with respect to this Legal notice, the Privacy policy, or other
          Information on the website, please do not hesitate to
          <Link href="https://www.novozymes.com/en/about-us/contact-us" target="_blank">
            &nbsp;contact Novozymes
          </Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">INPUT DATA</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          When you use this Website, you are requested to provide input regarding the following input data in relation
          to your company (“Input Data”):
          <ol>
            <li>
              1. Production capacity: In order for the calculator to provide the best guidance/recommendation on your
              gross benefit, you need to specify details on production volumes and production capacity.
            </li>
            <li>
              2. Currency and weight: In order for the calculator to provide the most relevant guidance/recommendation,
              you need to specify what location your plant is at in order to accommodate regional assumptions about
              prices and measuring units.
            </li>
            <li>
              3. Plant setup: In order for the calculator to provide the best guidance/recommendation on your gross
              benefit, you need to specify setup of your plant in terms of production of ethanol, feed dryer and if you
              are forced to sell steep liquor.
            </li>
          </ol>
          The Input data,when entered, is used within the Novozymes group as an input for the calculator to deliver the
          recommendations on this Website, as well as improve the functionality of the calculator, potentially jointly
          with external advisors subject to appropriate confidentiality obligations. When explicitly providing
          identification details, such as name and email, we will pair the Input Data provided with your identify in
          order to engage in a dialogue. We will only do this, when clear consent is provided. Further details on data
          privacy are
          <Link href="https://www.novozymes.com/en/privacy" target="_blank">
            &nbsp;given here
          </Link>
          . By providing the Input Data you authorize Novozymes and its affiliates to use the Input Data for these
          purposes. Novozymes undertakes to keep your Input Data confidential. We do not share your Input Data with
          third parties, other than as described above.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">DISCLAIMER</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          The documents and information on the Website (“Information”), including advice and recommendations are
          provided “as is”, “with all faults” and “as available” without warranties of any kind, whether express or
          implied, including, but not limited to, the implied warranties of merchantability, fitness for a particular
          purpose, and noninfringement. The Information on this site may include technical inaccuracies and errors.
          Novozymes does not guarantee the correctness, accuracy, reliability or completeness of the Information, and
          does not assume any liability under tort or contract law or any other legal theory for losses or failures of
          any kind related to the use of the Information. Novozymes is not responsible for the contents of any off-site
          pages or any other sites linked to or from this site. The Information on the Website is published for general
          information purposes only and does not constitute specific advice or recommendations. Any action you take upon
          the information on the Website is strictly at your own risk. You acknowledge that computer and
          telecommunications systems are not fault-free and that Novozymes does not guarantee the connection to or
          transmission from computer networks. Novozymes and/or its affiliates shall in no event be held liable for
          damages (whether direct or indirect), injury, or losses of any kind, including content loss, resulting from
          use of, access to, or inability to access the Information on the Website.
        </Typography>
      </Box>

      <Box m={2}>
        <Typography variant="h2">INTELLECTUAL PROPERTY RIGHTS</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          All Information on this site, including, but not limited to, any name, logo, text, software, product
          descriptions, formulas, photographs, video graphics or other material, is owned or licensed by Novozymes
          whether or not protected by copyright, trademarks, service marks, patents or other proprietary rights and
          laws. Nothing contained herein shall be construed as conferring any license or right under any patent or
          trademark of Novozymes or any third party. Any unauthorized use of these names, materials, etc., may be
          subject to penalties or damages, including, but not limited to, those related to violations of trademarks,
          copyrights and publicity rights.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">GOVERNING LAW AND VENUE</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          This Legal notice and other Information on the Website and any use thereof are governed exclusively by Danish
          law without regard to rules on choice of law. Any disputes arising out of or in connection with the Website
          shall be referred to the jurisdiction of the Danish ordinary courts.
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">COOKIES</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          See <Link href="/cookies">Cookies</Link>
        </Typography>
      </Box>
      <Box m={2}>
        <Typography variant="h2">CONTACT</Typography>
      </Box>
      <Box m={2}>
        <Typography variant="body1">
          If you have any questions or comments with respect to this Legal notice, the Privacy policy, or other
          Information on the website, please do not hesitate to{' '}
          <Link href="mailto:legal@novozymes.com">contact Novozymes</Link>
        </Typography>
      </Box>
    </Box>
  </>
);

export default TermsConditions;
