/* eslint-disable comma-dangle */
import React, { ReactElement, useState, useEffect } from 'react';
import { Box, makeStyles } from '@material-ui/core';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { initialAppState, updateAppState } from 'appState/AppState';
import { Footer } from '@novozymes-digital/components';
import TermsConditions from 'pages/TermsConditions';
import Result from 'pages/Result';
import Header from 'layout/Header';
import Body from 'layout/Body';
import { AppState, InputData, PriceInput } from 'appState/AppStateTypes';
import getEuroToUsd from 'services/CurrencyService';
import Converter from 'utils/Converter';
import Cookies from 'pages/Cookies';
import Privacy from 'pages/Privacy';
import IEUnsupportedDialog from 'components/dialogs/IEUnsupportedDialog';
import { initializeCookieConsent, initializeGTM } from '@novozymes-digital/utils';
import useIsDesktop from 'utils/MediaQueryHelper';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#f6f7f5',
    minHeight: '100%',
    [theme.breakpoints.up(1280)]: {
      overflowX: 'hidden',
    },
  },
}));

function App(): ReactElement {
  const [appState, setAppState] = useState(initialAppState);
  const [converter, setConverter] = useState<Converter | undefined>(undefined);

  const classes = useStyles();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    getEuroToUsd().then((euroToUsd) => setConverter(new Converter(euroToUsd)));

    // Cookie consent load
    initializeCookieConsent();

    initializeGTM('GTM-KTWCZXC');
  }, []);

  const handleInputChange =
    (attr: string) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {
      const appStateClone: AppState = { ...appState };
      const value = parseFloat(event.target.value);
      appStateClone.inputFields = Object.fromEntries(
        Object.entries(appStateClone.inputFields).map(([k, field]) => [k, k === attr ? { ...field, value } : field])
      ) as InputData;

      appStateClone.priceInputFields = Object.fromEntries(
        Object.entries(appStateClone.priceInputFields).map(([k, field]) => [
          k,
          k === attr ? { ...field, value } : field,
        ])
      ) as PriceInput;

      if (converter) {
        setAppState(updateAppState(appStateClone, appState, converter));
      }
    };

  const updateSeveralValues = (newValues: { attr: string; value: number }[]) => {
    const appStateClone: AppState = { ...appState };
    newValues.forEach(({ attr, value }) => {
      appStateClone.inputFields = Object.fromEntries(
        Object.entries(appStateClone.inputFields).map(([k, field]) => [k, k === attr ? { ...field, value } : field])
      ) as InputData;

      appStateClone.priceInputFields = Object.fromEntries(
        Object.entries(appStateClone.priceInputFields).map(([k, field]) => [
          k,
          k === attr ? { ...field, value } : field,
        ])
      ) as PriceInput;
    });

    if (converter) {
      setAppState(updateAppState(appStateClone, appState, converter));
    }
  };

  const handleSelectChange =
    (attr: string) =>
    (event: React.ChangeEvent<{ value: unknown }>): void => {
      const appStateClone: AppState = { ...appState };
      appStateClone.inputFields = Object.fromEntries(
        Object.entries(appStateClone.inputFields).map(([k, field]) => [
          k,
          k === attr ? { ...field, value: event.target.value as number } : field,
        ])
      ) as InputData;
      if (converter) {
        setAppState(updateAppState(appStateClone, appState, converter));
      }
    };

  return (
    <Box className={classes.container}>
      <IEUnsupportedDialog />
      <Router>
        <Header appState={appState} />
        <Switch>
          <Route exact path="/">
            <Result
              handleInputChange={handleInputChange}
              handleSelectChange={handleSelectChange}
              updateSeveralValues={updateSeveralValues}
              appState={appState}
            />
          </Route>
          <Route path="/terms">
            <Body>
              <TermsConditions />
            </Body>
          </Route>
          <Route path="/cookies">
            <Body>
              <Cookies />
            </Body>
          </Route>
          <Route path="/privacy">
            <Body>
              <Privacy />
            </Body>
          </Route>
        </Switch>
        <Box style={{ paddingLeft: isDesktop ? undefined : '0.5rem' }}>
          <Footer />
        </Box>
      </Router>
    </Box>
  );
}

export default App;
