const typography = {
  hb1: {
    fontSize: '56px',
    fontWeight: 700,
    lineHeight: '64px',
  },
  hb2: {
    fontSize: '48px',
    fontWeight: 700,
    lineHeight: '56px',
  },
  hb3: {
    fontSize: '32px',
    fontWeight: 700,
    lineHeight: '40px',
  },
  hb4: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '28px',
  },
  hb5: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '22px',
  },
  hb6: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  hb7: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  hb8: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '16px',
  },
  hb9: {
    fontSize: '8px',
    fontWeight: 700,
    lineHeight: '12px',
  },
  h1: {
    fontSize: '56px',
    fontWeight: 400,
    lineHeight: '64px',
  },
  h2: {
    fontSize: '48px',
    fontWeight: 400,
    lineHeight: '56px',
  },
  h3: {
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '40px',
  },
  h4: {
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '32px',
  },
  p1: {
    fontSize: '20px',
    fontWeight: 400,
    lineHeight: '28px',
  },
  p2: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
  },
  p3: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  p4: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
  },
  p5: {
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  p6: {
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '10px',
  },
  caption1: {
    fontSize: '8px',
    fontWeight: 400,
    lineHeight: '12px',
  },
};

export default typography;
