import { FuelType } from 'staticData/Fuel';
import { Dictionary } from 'types/Dictionary';
import { CalculateStaticData, CalculateInput } from './CalculationTypes';
import { ByproductSaving, byproductSaving } from './comon/EnergySaving';

const calculateEnergy = (staticData: CalculateStaticData, inputData: CalculateInput): Dictionary<number> => {
  const energyCalculations: Dictionary<number> = {};

  const byproductSavings: Dictionary<ByproductSaving> = {};

  const fuelPrice =
    inputData.fuelType === FuelType.NaturalGas ? staticData.energyData.priceGas : staticData.energyData.priceCoal;

  byproductSavings.fiber = byproductSaving(0.61, 0.504, staticData.endProducts.CGF, true, staticData);

  byproductSavings.germ = byproductSaving(0.52, 0.52, staticData.endProducts.Germ, true, staticData);

  byproductSavings.gluten = byproductSaving(0.6, 0.6, staticData.endProducts.CGM, true, staticData);

  const evapLsw = 0.12;
  const evapHsw = 0.48;

  const cslProduction = staticData.plantCapacityData.millingRate * staticData.processData.CSL;

  // Evaporation needs
  const feed = cslProduction / evapLsw;
  const efficiency = 2.5;

  const output = cslProduction / evapHsw;
  const steamCost = fuelPrice / (staticData.energyData.gasEnergyUtilization * staticData.energyData.boilerYield);

  const evaporation = feed - output;
  const tonSteamWithDryer = evaporation / efficiency;

  const savings =
    staticData.processData.Fiber *
    staticData.plantCapacityData.millingRate *
    (1 / (1 - byproductSavings.fiber.moistureBefore) - 1 / (1 - byproductSavings.fiber.moistureAfter));
  const tonSteamWithoutDryer = (evaporation - savings) / efficiency;

  const costWithDryer = tonSteamWithDryer * steamCost;
  const costWithoutDryer = tonSteamWithoutDryer * steamCost;

  energyCalculations.savings = (costWithDryer - costWithoutDryer) * inputData.daysOperating;

  return energyCalculations;
};

export default calculateEnergy;
