/* eslint-disable max-len */

import React, { ReactElement, useState, useEffect, useRef, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  makeStyles,
  MenuItem,
} from '@material-ui/core';
import CtaButton from 'components/CtaButton';
import { AppState, YesNo } from 'appState/AppStateTypes';
import { Region } from 'staticData/Region';
import CountryOptions from 'staticData/CountryOptions';
import CookieHelper from 'utils/CookieHelper';
import { useTranslation, Trans } from 'react-i18next';
import { getClientId, pardotFormHandler } from '@novozymes-digital/utils';

type DownloadDialogProps = {
  show: boolean;
  onClose(cancelled: boolean): void;
  appState: AppState;
  dialogTitle?: string;
  dialogIntro?: string;
  pardotEndpoint?: string;
};

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  textAlignCenter: {
    textAlign: 'center',
  },
}));

const PARDOT_FORM_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-06-16/c688b';

export default function DownloadDialog({
  show,
  onClose,
  appState,
  dialogTitle,
  dialogIntro,
  pardotEndpoint,
  ...props
}: DownloadDialogProps): ReactElement {
  const [open, setOpen] = useState(show);
  const formRef = useRef<HTMLFormElement>(null);

  const pardotData = {
    region: Region[appState.inputFields.region.value],
    daysOperating: appState.inputFields.daysOperating.value,
    daysFullCapacity: appState.inputFields.daysFullCapacity.value,
    totalCapcity: appState.inputFields.totalCapcity.value,
    produceEthanol: YesNo[appState.inputFields.produceEthanol.value],
    feedDryer: YesNo[appState.inputFields.feedDryer.value],
    sellSteepLiqour: YesNo[appState.inputFields.sellSteepLiqour.value],
    excessCornSteepLiqour: appState.inputFields.excessCornSteepLiqour.value,
    priceInput: {
      Corn: appState.priceInputFields.Corn.value,
      CGF: appState.priceInputFields.CGF.value,
      CSL: appState.priceInputFields.CSL.value,
      CGM: appState.priceInputFields.CGM.value,
      Germ: appState.priceInputFields.Germ.value,
      Ethanol: appState.priceInputFields.Ethanol.value,
      NativeStarch: appState.priceInputFields.NativeStarch.value,
    },
  };

  // eslint-disable-next-line no-undef
  const sourceUrl = window.location.href;
  const gaId = getClientId();

  const classes = useStyles();

  const handleClose = useCallback((): void => {
    setOpen(false);
    onClose(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCancelled = useCallback(() => {
    setOpen(false);
    onClose(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleSetCookie = useCallback((data): void => {
    const oneMonth = 60 * 60 * 24 * 30;
    CookieHelper.getInstance().set('user', JSON.stringify(data), oneMonth);
  }, []);

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>): void => {
      e.preventDefault();

      if (formRef.current == null) {
        return;
      }

      pardotFormHandler(pardotEndpoint || PARDOT_FORM_ENDPOINT, formRef.current, (data) => {
        handleSetCookie(data);
        handleClose();
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formRef]
  );

  const { t } = useTranslation('contactForm');

  return (
    <Dialog
      open={open}
      onClose={handleCancelled}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <Box m={2}>
        <DialogTitle disableTypography={false} id="alert-dialog-title">
          <Typography variant="h2">{dialogTitle || t('downloadHeading')}</Typography>
          <Typography variant="body1">{dialogIntro || t('downloadIntro')}</Typography>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={onSubmit} ref={formRef}>
            <Grid container>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    required
                    name="first_name"
                    label={t('firstName')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    required
                    name="last_name"
                    label={t('lastName')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    required
                    name="email"
                    label={t('eMail')}
                    type="mail"
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    required
                    name="company"
                    label={t('company')}
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <TextField
                    className={classes.fullWidth}
                    variant="outlined"
                    select
                    required
                    name="location"
                    label={t('location')}
                  >
                    {CountryOptions.map((option) => (
                      <MenuItem value={option.value} key={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <FormControlLabel
                    control={<Checkbox required value="True" name="i_accept_terms" />}
                    label={
                      <Typography variant="caption">
                        <Trans
                          ns="contactForm"
                          i18nKey="agreeTermsConditions"
                          components={{
                            terms: <a target="_blank" rel="noopener noreferrer" href="/terms" />,
                            privacy: (
                              <a
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://www.novozymes.com/en/privacy"
                              />
                            ),
                          }}
                        />
                      </Typography>
                    }
                  />
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box m={2}>
                  <FormControlLabel
                    control={<Checkbox value="True" name="i_opt_in" />}
                    label={<Typography variant="caption">{t('occasionalUpdates')}</Typography>}
                  />
                </Box>
              </Grid>
              <input type="hidden" name="subject" value="Other" />
              <input type="hidden" name="area_of_business" value="Food and Beverages" />
              <input type="hidden" name="industry_segment" value="Starch" />
              <input type="hidden" name="pardot_product_inquiry" value="Whitepaper download" />
              <input type="hidden" name="product" value="Frontia" />
              <input type="hidden" name="pardot_source" value={sourceUrl} />
              <input type="hidden" name="ga_client_id" value={gaId} />
              <input type="hidden" name="pardot_calculator_values" value={JSON.stringify(pardotData)} />
              <Grid item xs={12} className={classes.textAlignCenter}>
                <CtaButton
                  type="submit"
                  id="result_downloadForm"
                  value="submit"
                  variant="contained"
                  color="primary"
                  data-e2e="ResultPage-DownloadForm"
                >
                  {t('submit')}
                </CtaButton>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
      </Box>
    </Dialog>
  );
}
