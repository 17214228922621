import { Dictionary } from 'types/Dictionary';
import { YesNo } from 'appState/AppStateTypes';
import { FuelType } from 'staticData/Fuel';
import { CalculateStaticData, CalculateInput } from './CalculationTypes';
import { yeildBeforeFrontia, YeildSaving, yeildSaving } from './comon/ProcessYeildSavings';
import { EnergySaving, computeExtraClsDrying, ByproductSaving, byproductSaving } from './comon/EnergySaving';

export type LessCornMillingScenario = {
  beforeFrontia: Dictionary<YeildSaving>;
  afterFrontia: Dictionary<YeildSaving>;
  energySavings: Dictionary<EnergySaving>;
  extraClsDrying: Dictionary<number>;
  electricitySavings: number;
  lessCornMilling: Dictionary<number>;
};

const calculateLessCornMilling = (
  inputStatic: CalculateStaticData,
  inputUser: CalculateInput,
  backgroundEnergySavings: Dictionary<number>
): LessCornMillingScenario => {
  const scenario: LessCornMillingScenario = {
    beforeFrontia: {},
    afterFrontia: {},
    energySavings: {},
    extraClsDrying: {},
    electricitySavings: 0,
    lessCornMilling: {},
  };

  scenario.beforeFrontia = yeildBeforeFrontia(inputStatic, inputUser);

  scenario.afterFrontia.corn = yeildSaving(
    -(
      (scenario.beforeFrontia.starch.production * (1 - inputStatic.endProducts.Starch)) /
      (inputStatic.processData.Starch + inputStatic.enzymeData.starch.recovered)
    ) /
      (1 - inputStatic.plantCapacityData.cornMoisture),
    inputStatic.financialData.Corn
  );

  const cornProdMoisture = -scenario.afterFrontia.corn.production * (1 - inputStatic.plantCapacityData.cornMoisture);

  const fuelPrice =
    inputUser.fuelType === FuelType.NaturalGas ? inputStatic.energyData.priceGas : inputStatic.energyData.priceCoal;

  scenario.afterFrontia.csl = yeildSaving(
    (cornProdMoisture * (1 - inputStatic.plantCapacityData.clsFiberFrontia) * inputStatic.processData.CSL) /
      (1 - inputStatic.endProducts.CSL),
    inputStatic.financialData.CSL
  );

  scenario.afterFrontia.germ = yeildSaving(
    (cornProdMoisture * inputStatic.processData.Germ) / (1 - inputStatic.endProducts.Germ),
    inputStatic.financialData.Germ
  );

  scenario.afterFrontia.cgm = yeildSaving(
    (cornProdMoisture * (inputStatic.processData.CGM + inputStatic.enzymeData.gluten.recovered)) /
      (1 - inputStatic.endProducts.CGM),
    inputStatic.financialData.CGM
  );

  scenario.afterFrontia.cgf = yeildSaving(
    (cornProdMoisture *
      (inputStatic.processData.Fiber +
        inputStatic.processData.CSL * inputStatic.plantCapacityData.clsFiberFrontia -
        inputStatic.enzymeData.starch.recovered -
        inputStatic.enzymeData.gluten.recovered)) /
      (1 - inputStatic.endProducts.CGF),
    inputStatic.financialData.CGF
  );

  scenario.afterFrontia.starch = {
    production: scenario.beforeFrontia.starch.production,
    revenue: -(
      scenario.afterFrontia.corn.revenue +
      scenario.afterFrontia.csl.revenue +
      scenario.afterFrontia.germ.revenue +
      scenario.afterFrontia.cgm.revenue +
      scenario.afterFrontia.cgf.revenue
    ),
  };

  // Yearly Yield Benefit
  scenario.lessCornMilling.yearlyBenefitYeild =
    (scenario.beforeFrontia.starch.revenue - scenario.afterFrontia.starch.revenue) * inputUser.daysOperating;

  scenario.extraClsDrying = computeExtraClsDrying(inputStatic, inputUser);

  const byproductSavings: Dictionary<ByproductSaving> = {};

  byproductSavings.fiber = byproductSaving(0.61, 0.504, inputStatic.endProducts.CGF, true, inputStatic);
  byproductSavings.germ = byproductSaving(0.52, 0.52, inputStatic.endProducts.Germ, true, inputStatic);
  byproductSavings.gluten = byproductSaving(0.6, 0.6, inputStatic.endProducts.CGM, true, inputStatic);

  scenario.energySavings.fiber = {
    beforeFrontia:
      (scenario.beforeFrontia.cgf.production * inputStatic.energyData.fiberDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
    afterFrontia:
      ((scenario.afterFrontia.cgf.production - scenario.extraClsDrying.tonExtraCgf) *
        (inputStatic.energyData.fiberDryer * (1 - byproductSavings.fiber.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
  };

  scenario.energySavings.germ = {
    beforeFrontia:
      (scenario.beforeFrontia.germ.production * inputStatic.energyData.germDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization * inputStatic.energyData.boilerYield),
    afterFrontia:
      (scenario.afterFrontia.germ.production *
        (inputStatic.energyData.germDryer * (1 - byproductSavings.germ.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization * inputStatic.energyData.boilerYield),
  };

  scenario.energySavings.gluten = {
    beforeFrontia:
      (scenario.beforeFrontia.cgm.production * inputStatic.energyData.cgmDryer * fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
    afterFrontia:
      (scenario.afterFrontia.cgm.production *
        (inputStatic.energyData.cgmDryer * (1 - byproductSavings.gluten.saving)) *
        fuelPrice) /
      (1000 * inputStatic.energyData.gasEnergyUtilization),
  };

  scenario.energySavings.total = {
    beforeFrontia:
      scenario.energySavings.fiber.beforeFrontia +
      scenario.energySavings.germ.beforeFrontia +
      scenario.energySavings.gluten.beforeFrontia,
    afterFrontia:
      scenario.energySavings.fiber.afterFrontia +
      scenario.energySavings.germ.afterFrontia +
      scenario.energySavings.gluten.afterFrontia,
  };

  scenario.electricitySavings =
    (-(scenario.beforeFrontia.corn.production - scenario.afterFrontia.corn.production) *
      inputStatic.energyData.electricityRatio *
      inputStatic.energyData.priceElectricity) /
    1000;

  scenario.lessCornMilling.yearlyBenefitEnergy =
    inputUser.feedDryer === YesNo.yes
      ? (scenario.energySavings.total.beforeFrontia -
          scenario.energySavings.total.afterFrontia +
          scenario.electricitySavings) *
          inputUser.daysOperating -
        scenario.extraClsDrying.evaporationCost
      : (scenario.energySavings.germ.beforeFrontia +
          scenario.energySavings.gluten.beforeFrontia +
          scenario.electricitySavings -
          scenario.energySavings.germ.afterFrontia -
          scenario.energySavings.gluten.afterFrontia) *
          inputUser.daysOperating -
        scenario.extraClsDrying.evaporationCost +
        backgroundEnergySavings.savings;

  scenario.lessCornMilling.totalGrossBenefit =
    scenario.lessCornMilling.yearlyBenefitYeild + scenario.lessCornMilling.yearlyBenefitEnergy;

  return scenario;
};

export default calculateLessCornMilling;
