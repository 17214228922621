export type LCAData = {
  fiberYield: number;
  fiberDryerEfficiency: number;
  moistureCommercialCorn: number;
  moistureStarch: number;
  moistureCornSteepLiquor: number;
  yieldConventional: number;
  fiberMoisture: number;
  totalStarchInFiber: number;
  cslSprayOnFiber: number;
  frontiaJadeDosage: number;
};

export const lcaData: LCAData = {
  fiberYield: 0.086, // kg fibers(dm) / kg corn(dm)
  fiberDryerEfficiency: 1.4, // kg steam / kg water
  moistureCommercialCorn: 0.14, // kg water / kg commercial corn
  moistureStarch: 0.14, // kg water / kg starch
  moistureCornSteepLiquor: 0.615, // kg water / kg CSL
  yieldConventional: 0.7, // 70% kg starch(dm) / kg corn(dm) ( % )
  fiberMoisture: 59.27,
  totalStarchInFiber: 20.21,
  cslSprayOnFiber: 25.6,
  frontiaJadeDosage: 0,
};

export const conventionalInputReference = {
  commercialCorn: 1000, // Commercial corn (incl 14% moisture)
  tapWater: 1500, // Tap water (room temperature)
  steam: 740, // Steam
  electricity: 856, // Electricity
  capitalGoods: 0.0000000004 * 602, // Capital goods*
  wastewater: 0.416, // Wastewater*
};

export const conventionalOutputReference = {
  cornGerm: 60, // Corn germ (for corn oil and corn germ meal)
  CGM: 47, // CGM = Corn gluten meal (CGM)
  CSL: 22, // CSL rest (= CSL that is not sprayed but used for other applications)
  CGF: 112, // CGF = Corn Gluten Feed (Fibers + CSL spray)
};

export const conventionalFactorReference = {
  commercialCorn: 0.47287916241003,
  tapWater: 0.00102537357240215,
  electricity: 0.224660577564137,
  capitalGoods: 162299411.319086,
  wastewater: 6.51461274978738,
  cornGerm: -3.08191118541799,
  CGM: 0.230283182614189,
  CGF: -0.383911827704549,
};
