/* eslint-disable max-len */
import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import { Box, Grid, makeStyles, Fade, SwipeableDrawer } from '@material-ui/core';
import { Right } from '@novozymes-digital/components';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AppState } from 'appState/AppStateTypes';
import ResultsSidebar from 'components/PlantInput';
import useIsDesktop from 'utils/MediaQueryHelper';
import PriceInputDialog from 'components/dialogs/PriceInputDialog';
import DownloadDialog from 'components/dialogs/DownloadDialog';
import { formatCurrency } from 'utils/NumberHelper';
import BenefitDisplay from 'components/dataDisplay/BenefitDisplay';
import CookieHelper from 'utils/CookieHelper';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    overflowY: 'auto',
    width: '100vw',
    minHeight: `calc(${window.innerHeight}px - 125px)`,
    [theme.breakpoints.down(1280)]: {
      paddingTop: '65px',
      minHeight: 'calc(100% - 45px)',
    },
    [theme.breakpoints.down(600)]: {
      paddingTop: '50px',
    },
  },
  pageContainer: {
    margin: 'auto',
    backgroundColor: theme.palette.grey[100],
    maxWidth: '95rem',
  },
  sidebar: {
    padding: '20px 0px 8px 72px',
  },
  benefitsContainer: {
    padding: '0 4.5rem 2rem 1.5rem',
    [theme.breakpoints.down('md')]: {
      padding: '0 1.5rem 2rem 1.5rem',
    },
  },
  menuOpen: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    transform: 'scale(-1.5)',
    cursor: 'pointer',
    zIndex: 5,
  },
  menuClose: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.common.white,
    cursor: 'pointer',
    zIndex: 5,
    transform: 'scale(1.5)',
  },
  sideBarDrawer: {
    backgroundColor: '#FFF',
    width: '1rem',
    minHeight: `calc(${window.innerHeight}px - 125px)`,
    height: '100%',
    position: 'fixed',
    top: '65px',
    left: 0,
    boxShadow: '0px 2px 3px 1px rgb(0 0 0 / 20%)',
    [theme.breakpoints.down(600)]: {
      top: '50px',
    },
  },
}));

const drawerStyles = makeStyles((theme) => ({
  paper: {
    padding: '0 16px',
    width: '33%',
    [theme.breakpoints.down('md')]: {
      top: '65px',
      height: 'calc(100% - 65px)',
    },
    [theme.breakpoints.down(800)]: {
      width: '75%',
    },
    [theme.breakpoints.down(600)]: {
      top: '50px',
      height: 'calc(100% - 50px)',
      width: '95%',
    },
  },
}));

interface ResultProps extends RouteComponentProps {
  appState: AppState;
  handleInputChange(field: string): (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange(field: string): (event: React.ChangeEvent<{ value: unknown }>) => void;
  updateSeveralValues: (newValues: { attr: string; value: number }[]) => void;
}

const Result = ({
  handleInputChange,
  handleSelectChange,
  updateSeveralValues,
  appState,
}: ResultProps): ReactElement => {
  const classes = useStyles();
  const drawerClasses = drawerStyles();
  const isDesktop = useIsDesktop();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [allInputAvailable, setAllInputAvailable] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showContactForm, setShowContactForm] = useState(false);

  const PARDOT_ENDPOINT = 'https://nz.engage.novozymes.com/l/701243/2020-10-05/cx144';

  const onClosePriceModal = useCallback(() => setShowPriceModal(false), []);
  const appStateClone = cloneDeep(appState);
  appStateClone.priceInputFields.Ethanol.hidden = true;
  useEffect(() => {
    const unfilledInput = Object.values(appState.inputFields)
      .filter((field) => !field.hidden)
      .some((field) => Number.isNaN(field.value));

    setAllInputAvailable(!unfilledInput);
    if (isDesktop) {
      setShowResults(!unfilledInput);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appState]);

  const onPriceClick = useCallback(() => {
    if (CookieHelper.getInstance().get('user')) {
      setShowContactForm(false);
      setShowPriceModal(true);
    } else {
      setShowContactForm(true);
    }
  }, []);

  const onShowResults = useCallback(() => {
    setShowResults(true);
    setOpenDrawer(false);
  }, []);

  const onCloseContact = useCallback(() => {
    setShowContactForm(false);
    if (CookieHelper.getInstance().get('user')) {
      setShowPriceModal(true);
    }
  }, []);

  const { t } = useTranslation('plantInput');

  return (
    <>
      <PriceInputDialog
        show={showPriceModal}
        onClose={onClosePriceModal}
        appState={appStateClone}
        updateSeveralValues={updateSeveralValues}
      />
      <DownloadDialog
        show={showContactForm}
        onClose={onCloseContact}
        appState={appState}
        dialogTitle={t('customizeDialogTitle')}
        dialogIntro={t('customizeDialogIntro')}
        pardotEndpoint={PARDOT_ENDPOINT}
      />

      <div className={classes.mainContainer}>
        {!isDesktop && (
          <Box className={classes.sideBarDrawer}>
            <Box style={{ position: 'absolute', top: 30 }} onClick={(): void => setOpenDrawer(true)}>
              <Right className={classes.menuOpen} />
            </Box>
          </Box>
        )}
        <Grid container wrap="nowrap" className={classes.pageContainer}>
          {isDesktop ? (
            <Grid item md={4}>
              <Box className={classes.sidebar}>
                <ResultsSidebar
                  data-e2e="ResultPage-Sidebar"
                  appState={appState}
                  handleInputChange={handleInputChange}
                  handleSelectChange={handleSelectChange}
                  updateSeveralValues={updateSeveralValues}
                  onPriceClick={onPriceClick}
                  onShowResults={onShowResults}
                  allInputAvailable={allInputAvailable}
                />
              </Box>
            </Grid>
          ) : (
            <SwipeableDrawer
              anchor="left"
              onOpen={(): void => setOpenDrawer(true)}
              open={openDrawer}
              onClose={(): void => setOpenDrawer(false)}
              classes={drawerClasses}
              swipeAreaWidth={10}
            >
              <ResultsSidebar
                appState={appState}
                handleInputChange={handleInputChange}
                handleSelectChange={handleSelectChange}
                updateSeveralValues={updateSeveralValues}
                onPriceClick={onPriceClick}
                onShowResults={onShowResults}
                allInputAvailable={allInputAvailable}
              />
            </SwipeableDrawer>
          )}

          <Grid item md={isDesktop ? 8 : 12} xs={12} style={{ paddingLeft: isDesktop ? '' : '0.5rem' }}>
            <Grid container className={classes.benefitsContainer}>
              <Fade in timeout={1000}>
                <Grid container>
                  <Grid item xs={12}>
                    <BenefitDisplay
                      grossbenefit={appState.calculateData.grossbenefit}
                      higherSales={appState.calculateData.higherSales}
                      energySavings={appState.calculateData.energySavings}
                      starchCost={appState.calculateData.starchCost}
                      payBack={appState.calculateData.payBack}
                      flexibility={appState.calculateData.flexibility}
                      sustainability={appState.sustainabilityData}
                      formatLabel={(value: number, decimals?: number): string =>
                        formatCurrency(value, appState.inputFields.region.value, decimals)
                      }
                      updateSeveralValues={updateSeveralValues}
                      loading={!showResults}
                      appState={appState}
                      onPriceClick={onPriceClick}
                      setOpenDrawer={setOpenDrawer}
                    />
                  </Grid>
                </Grid>
              </Fade>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default withRouter(Result);
