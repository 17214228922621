import calculateData from 'calculation/CalculateData';
import calculateSustainabilityData from 'calculation/CalculateSustainabilityData';
import { CalculateInput, CalculatePriceInput, CalculateOutput } from 'calculation/CalculationTypes';
import Converter from 'utils/Converter';
import roundValue from 'utils/NumberHelper';
import { Region } from 'staticData/Region';
import { cloneDeep, isEqual } from 'lodash';
import { AppState, InputData, YesNo } from './AppStateTypes';
import DefaultAppState from './AppStateDefault';
import priceInputFields from './priceInputFields';
import pardotOnPriceUpdate from './pardotOnPriceUpdate';

export const initialAppState = (): AppState => {
  const appState: AppState = DefaultAppState;

  return appState;
};

const updateRegionFields = (input: InputData, region: Region, prevRegion: Region, converter: Converter): InputData => {
  const fields = { ...input };

  fields.totalCapcity.adornment = region === Region.NorthAmerica ? 'Bushel/day' : 'Tons/day';

  fields.totalCapcity.default = region === Region.NorthAmerica ? 100000 : 1000;

  /*Changes for NA */
  fields.daysFullCapacity.hidden = region === Region.NorthAmerica ? true : false;

  if (Number.isNaN(prevRegion)) {
    return fields;
  }

  // Convert weight
  fields.totalCapcity.value = roundValue(
    converter.weightToRegion(region, prevRegion, fields.totalCapcity.value as number)
  );

  return fields;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const transform = (obj: any, fn: (n: any) => number): any => {
  return Object.fromEntries(
    Object.entries(obj).map(([k, value]) => {
      switch (typeof value) {
        case 'object':
          return [k, transform(value, fn)];
        case 'number':
          return [k, fn(value)];
        default:
          return [k, undefined];
      }
    })
  );
};

export const updateAppState = (appState: AppState, previousAppState: AppState, converter: Converter): AppState => {
  const clone = cloneDeep(appState);

  // Set show hide for fields

  clone.inputFields.excessCornSteepLiqour.hidden =
    clone.inputFields.sellSteepLiqour.value === YesNo.no || Number.isNaN(clone.inputFields.sellSteepLiqour.value);
  clone.inputFields.excessCornSteepLiqour.value =
    clone.inputFields.sellSteepLiqour.value === YesNo.no ? 0 : clone.inputFields.excessCornSteepLiqour.value;
  if (!isEqual(appState.priceInputFields, previousAppState.priceInputFields)) {
    pardotOnPriceUpdate(clone);
  }

  if (Number.isNaN(clone.inputFields.region.value)) {
    return clone;
  }

  clone.inputFields = updateRegionFields(
    clone.inputFields,
    clone.inputFields.region.value as Region,
    previousAppState.inputFields.region.value as Region,
    converter
  );
  if (clone.inputFields.region.value !== previousAppState.inputFields.region.value) {
    clone.priceInputFields = priceInputFields(clone.inputFields.region.value);
    clone.inputFields.daysFullCapacity.default = clone.inputFields.region.value === 1 ? 0 : 150;
    clone.inputFields.daysFullCapacity.value =
      clone.inputFields.region.value === 1
        ? 0
        : previousAppState.inputFields.region.value != 1
        ? clone.inputFields.daysFullCapacity.value
        : NaN;
  }

  if (Object.values(clone.inputFields).every((field) => !Number.isNaN(field.value))) {
    const calInput = Object.fromEntries(
      Object.entries(clone.inputFields).map(([k, field]) => [k, field.value])
    ) as CalculateInput;

    const priceInput = Object.fromEntries(
      Object.entries(clone.priceInputFields).map(([k, field]) => [k, field.value])
    ) as CalculatePriceInput;

    const result = calculateData(calInput, priceInput, converter);

    const convertPrice = (price: number) => converter.currency(Region.Europe, clone.inputFields.region.value, price);

    const transformedResult: CalculateOutput = transform(result, convertPrice);

    clone.calculateData = transformedResult;
    clone.sustainabilityData = calculateSustainabilityData(calInput, priceInput, converter);
  }

  return clone;
};
