import locations, { Region } from 'staticData/Region';
import fuelOptions from 'staticData/Fuel';
import { InputFieldType, YesNo, InputOption, InputData } from './AppStateTypes';

const yesNoOptions: InputOption[] = [
  {
    label: 'Yes',
    value: YesNo.yes,
  },
  {
    label: 'No',
    value: YesNo.no,
  },
];

const inputFields = (region: Region): InputData => ({
  region: {
    name: 'region',
    dataId: 'plantlocation',
    default: '',
    value: region,
    label: 'Where is your plant located?',
    type: InputFieldType.select,
    options: locations,
  },
  daysOperating: {
    name: 'daysOperating',
    dataId: 'daysoperating',
    default: 350,
    value: NaN,
    label: 'How many days per year is your plant operating?',
    adornment: 'days',
    type: InputFieldType.number,
  },
  daysFullCapacity: {
    name: 'daysFullCapacity',
    dataId: 'daysfullcapacity',
    default: 150,
    value: NaN,
    hidden: false,
    label: 'How many days per year is your plant working at full capacity?',
    adornment: 'days',
    type: InputFieldType.number,
  },
  totalCapcity: {
    name: 'totalCapcity',
    dataId: 'totalcapacity',
    default: 100000,
    value: NaN,
    label: 'What is your total capacity?',
    adornment: 'Bushel/day',
    type: InputFieldType.number,
  },
  produceEthanol: {
    name: 'produceEthanol',
    dataId: 'produceethanol',
    default: '',
    value: YesNo.no,
    label: 'Can your plant produce ethanol?',
    type: InputFieldType.select,
    options: yesNoOptions,
    /*Changes for 2022 */
    hidden: true,
  },
  feedDryer: {
    name: 'feedDryer',
    dataId: 'feeddryer',
    default: 0,
    value: YesNo.yes,
    hidden: true,
    label: 'Do you have a feed dryer?',
    type: InputFieldType.select,
    options: yesNoOptions,
  },
  fuelType: {
    name: 'fuelType',
    dataId: 'fueltype',
    default: '',
    value: 0,
    label: 'What is your fuel type?',
    type: InputFieldType.select,
    options: fuelOptions,
    hidden: true,
  },
  sellSteepLiqour: {
    name: 'sellSteepLiqour',
    dataId: 'steepliquor',
    default: '',
    value: YesNo.None,
    label: 'Are you forced to sell some of your steep liquor?',
    type: InputFieldType.select,
    options: yesNoOptions,
  },
  excessCornSteepLiqour: {
    name: 'excessCornSteepLiqour',
    dataId: 'productionvolume',
    default: 10,
    value: NaN,
    hidden: true,
    label: 'How much as a percentage is your excess corn steep liquor compared to your total annual production volume?',
    adornment: '%',
    type: InputFieldType.number,
  },
  // fiberMoisture: {
  //   name: 'fiberMoisture',
  //   default: 5,
  //   value: NaN,
  //   label: 'Fiber Moisture',
  //   adornment: '%',
  //   type: InputFieldType.number,
  // },
  // cslSprayOnFiber: {
  //   name: 'cslSprayOnFiber',
  //   default: 5,
  //   value: NaN,
  //   label: 'CSL spray on fiber',
  //   type: InputFieldType.number,
  // },
  // frontiaJadeDosage: {
  //   name: 'frontiaJadeDosage',
  //   default: 5,
  //   value: NaN,
  //   label: 'Frontia Jade Dosage',
  //   type: InputFieldType.number,
  // },
  // totalStarchInFiber: {
  //   name: 'totalStarchInFiber',
  //   default: 5,
  //   value: NaN,
  //   label: 'Total Starch in Fiber',
  //   type: InputFieldType.number,
  // },
});
export default inputFields;
