import Cookies from 'universal-cookie';

export default class CookieHelper {
  private static instance: CookieHelper;

  private cookies: Cookies;

  public static CONSENT_COOKIE = 'CookieInformationConsent';

  private constructor() {
    this.cookies = new Cookies();
  }

  static getInstance(): CookieHelper {
    if (!CookieHelper.instance) {
      CookieHelper.instance = new CookieHelper();
    }

    return CookieHelper.instance;
  }

  set(name: string, value: string | Record<string, unknown>, maxAge?: number): void {
    const options = maxAge ? { path: '/', maxAge } : { path: '/' };
    this.cookies.set(name, value, options);
  }

  get(name: string): unknown {
    return this.cookies.get(name);
  }
}
