/* eslint-disable no-undef */
import React, { ReactElement, useState, useEffect, useCallback } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Box,
  makeStyles,
  IconButton,
  Link,
} from '@material-ui/core';
import { isIE } from '@novozymes-digital/utils';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function IEUnsupportedDialog(): ReactElement {
  const [show, setShow] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    setShow(isIE());
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  return (
    <Dialog
      open={show}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      onClose={handleClose}
    >
      <Box mx={2} my={4}>
        <DialogTitle id="alert-dialog-title">
          <Typography variant="h1">Limited support in browser</Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography variant="body1">Dear visitor</Typography>
            <Typography variant="body1">
              This app is not fully supported in Internet Explorer. Therefore, some features might not function
              properly. To get the full experience open the app in a different browser, such as{' '}
              <Link href={`microsoft-edge:${window.location.href}`}>Microsoft Edge</Link>.
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions />
      </Box>
    </Dialog>
  );
}
