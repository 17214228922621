import getFinancialData from 'calculation/presetData/FinancialInputs';
import { Region } from 'staticData/Region';
import getNativeStarchData from 'calculation/presetData/NativeStarchInput';
import { InputFieldType, PriceInput } from './AppStateTypes';

const inputFields = (region: Region): PriceInput => {
  const defaultPriceData = getFinancialData(region);
  const nativeStarchDefault = getNativeStarchData(region);
  const currency = region === Region.NorthAmerica || region === Region.LatinAmerica ? '$' : '€';
  const cornUnit = region === Region.NorthAmerica ? 'BU' : 'MT';
  const cgfCslCgmGermUnit = region === Region.NorthAmerica ? 'lb' : 'MT';
  const ethanolUnit = region === Region.NorthAmerica ? 'Gal' : 'MT';
  return {
    Corn: {
      name: 'Corn',
      default: defaultPriceData.Corn,
      value: defaultPriceData.Corn,
      label: `Price of corn (${currency}/${cornUnit})`,
      type: InputFieldType.number,
    },
    CGF: {
      name: 'CGF',
      default: defaultPriceData.CGF,
      value: defaultPriceData.CGF,
      label: `Price of CGF (${currency}/${cgfCslCgmGermUnit})`,
      type: InputFieldType.number,
    },
    CSL: {
      name: 'CSL',
      default: defaultPriceData.CSL,
      value: defaultPriceData.CSL,
      label: `Price of CSL (${currency}/${cgfCslCgmGermUnit})`,
      type: InputFieldType.number,
    },
    CGM: {
      name: 'CGM',
      default: defaultPriceData.CGM,
      value: defaultPriceData.CGM,
      label: `Price of CGM (${currency}/${cgfCslCgmGermUnit})`,
      type: InputFieldType.number,
    },
    Germ: {
      name: 'Germ',
      default: defaultPriceData.Germ,
      value: defaultPriceData.Germ,
      label: `Price of Germ (${currency}/${cgfCslCgmGermUnit})`,
      type: InputFieldType.number,
    },
    Ethanol: {
      name: 'Ethanol',
      default: defaultPriceData.Ethanol,
      value: defaultPriceData.Ethanol,
      label: `Price of Ethanol  (${currency}/${ethanolUnit})`,
      type: InputFieldType.number,
    },
    NativeStarch: {
      name: 'NativeStarch',
      default: nativeStarchDefault.nativeStarchPrice,
      value: nativeStarchDefault.nativeStarchPrice,
      label: `Price of Native starch (${currency}/MT)`,
      type: InputFieldType.number,
    },
  };
};
export default inputFields;
