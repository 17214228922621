export type PayBackData = {
  capexEstimate: number;
  netValue: number;
  payBackTimeMonths: number;
};
export const paybackData: PayBackData = {
  capexEstimate: 0,
  netValue: 0,
  payBackTimeMonths: 0,
};

export default paybackData;
