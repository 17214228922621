import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'formdata-polyfill';

/* eslint-disable no-undef */
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import { ThemeProvider, CssBaseline } from '@material-ui/core';
import frontiaTheme from './theme/FrontiaTheme';
import App from './App';

ReactDOM.render(
  <Suspense fallback="loading">
    <ThemeProvider theme={frontiaTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </Suspense>,
  document.getElementById('root')
);
