import React, { ReactElement } from 'react';
import { Grid, Box, Typography, makeStyles } from '@material-ui/core';
import { OutlinedInputWithLabel, SelectWithLabel, Button } from '@novozymes-digital/components';
import { useTranslation } from 'react-i18next';
import useIsDesktop from 'utils/MediaQueryHelper';
import { AppState, InputFieldType } from '../appState/AppStateTypes';
import typography from 'theme/typography';

type PlantInputProps = {
  appState: AppState;
  handleInputChange(field: string): (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  handleSelectChange(field: string): (event: React.ChangeEvent<{ value: unknown }>) => void;
  updateSeveralValues: (newValues: { attr: string; value: number }[]) => void;
  onPriceClick: () => void;
  onShowResults: () => void;
  allInputAvailable: boolean;
};

const useStyles = makeStyles(() => ({
  ...typography,
}));

const PlantInput = ({
  appState,
  handleInputChange,
  handleSelectChange,
  onPriceClick,
  onShowResults,
  allInputAvailable,
  ...props
}: PlantInputProps): ReactElement => {
  const isDesktop = useIsDesktop();
  const classes = useStyles();

  const { t } = useTranslation('plantInput');

  return (
    <>
      <div {...props} style={{ marginTop: !isDesktop ? '0' : '4.7rem' }}>
        <Box>
          <Box mt={3} mb={3} style={{ marginBottom: '30px' }}>
            <Typography variant="h3" className={classes.hb6}>
              {`${t('title')}:`}
            </Typography>
          </Box>
          <Box mt={2} mb={2}>
            <Grid container justify={isDesktop ? 'flex-start' : 'center'}>
              {Object.values(appState.inputFields)
                .filter((field) => !field.hidden)
                .map((field) => (
                  <Grid item xs={!isDesktop ? 12 : 11} key={field.label}>
                    <Box mb="32px">
                      {field.type === InputFieldType.number ? (
                        <OutlinedInputWithLabel
                          data-id={field.dataId}
                          tooltip={field.tooltip}
                          label={t(`inputFields.${field.name}`)}
                          value={Number.isNaN(field.value) ? '' : field.value}
                          adornment={field.adornment ?? ''}
                          placeholder={`e.g. ${field.default}`}
                          disabled={field.disabled}
                          handleChange={handleInputChange(field.name)}
                          decimalSeparator="."
                          formatInput
                        />
                      ) : (
                        <SelectWithLabel
                          key={`unique-${field.dataId}`}
                          data-id={field.dataId}
                          value={Number.isNaN(field.value) ? field.default : field.value}
                          label={t(`inputFields.${field.name}`)}
                          options={field.options ?? []}
                          handleChange={handleSelectChange(field.name)}
                        />
                      )}
                    </Box>
                  </Grid>
                ))}
            </Grid>
            {!isDesktop && (
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Box mb={'16px'} display="flex" justifyContent="center">
                  <Button
                    type="primary"
                    onClick={onShowResults}
                    disabled={allInputAvailable === false}
                    id="plant_input_show_results_CTA"
                  >
                    {t('getResults')}
                  </Button>
                </Box>

                <Button type="secondary" onClick={onPriceClick} id="plant_input_edit_price_CTA">
                  {t('editPrices')}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};

export default PlantInput;
