import { CalculateStaticData, CalculateInput } from 'calculation/CalculationTypes';
import { FuelType } from 'staticData/Fuel';
import { Dictionary } from 'types/Dictionary';

export type EnergySaving = {
  beforeFrontia: number;
  afterFrontia: number;
};

export type ByproductSaving = {
  moistureBefore: number;
  moistureAfter: number;
  waterB: number;
  waterA: number;
  waterF: number;
  totalW: number;
  saving: number;
};

export const computeExtraClsDrying = (
  inputStatic: CalculateStaticData,
  inputUser: CalculateInput
): Dictionary<number> => {
  const extraClsDrying: Dictionary<number> = {};

  const fuelPrice =
    inputUser.fuelType === FuelType.NaturalGas ? inputStatic.energyData.priceGas : inputStatic.energyData.priceCoal;

  extraClsDrying.clsToCgf =
    (inputStatic.plantCapacityData.millingRate *
      (inputStatic.plantCapacityData.clsFiberFrontia - inputUser.excessCornSteepLiqour) *
      inputStatic.processData.CSL *
      inputUser.daysOperating) /
    (1 - inputStatic.endProducts.CSL);

  extraClsDrying.cgfToCls = (1 * (1 - inputStatic.endProducts.CSL)) / (1 - inputStatic.endProducts.CGF);

  extraClsDrying.waterEvaporate = 1 - extraClsDrying.cgfToCls;

  extraClsDrying.evaporationCost =
    (extraClsDrying.clsToCgf * extraClsDrying.waterEvaporate * inputStatic.energyData.fiberDryer * fuelPrice) /
    (1000 * inputStatic.energyData.gasEnergyUtilization);

  extraClsDrying.tonExtraCgf = (extraClsDrying.clsToCgf * extraClsDrying.cgfToCls) / inputUser.daysOperating;

  return extraClsDrying;
};

export const byproductSaving = (
  moistureBefore: number,
  moistureAfter: number,
  waterFVar: number,
  isFiber: boolean,
  staticData: CalculateStaticData
): ByproductSaving => {
  const waterB = 1 / (1 - moistureBefore);
  const waterA = 1 / (1 - moistureAfter);
  const waterF = 1 / (1 - waterFVar);

  const totalW = waterB - waterF;
  let saving = 0;

  if (isFiber) {
    saving =
      (((waterB - waterA) / totalW) * staticData.processData.Fiber) /
      (staticData.processData.Fiber + staticData.processData.CSL);
  } else {
    saving = (waterB - waterA) / totalW;
  }

  return {
    moistureBefore,
    moistureAfter,
    waterB,
    waterA,
    waterF,
    totalW,
    saving,
  };
};
