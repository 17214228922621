import { Region } from 'staticData/Region';

export type NativeStarchData = {
  dryingProcessCost: number;
  nativeStarchPrice: number;
};

const europe: NativeStarchData = {
  dryingProcessCost: 25,
  nativeStarchPrice: 1060,
};

const northAmerica: NativeStarchData = {
  dryingProcessCost: 25,
  nativeStarchPrice: 478,
};

const middleEast: NativeStarchData = {
  dryingProcessCost: 25,
  nativeStarchPrice: 1060,
};

const latinAmerica: NativeStarchData = {
  dryingProcessCost: 25,
  nativeStarchPrice: 1060,
};

const chinaAPAC: NativeStarchData = {
  dryingProcessCost: 25,
  nativeStarchPrice: 1060,
};

const getNativeStarchData = (region: Region): NativeStarchData => {
  switch (region) {
    case Region.Europe:
      return europe;
    case Region.LatinAmerica:
      return latinAmerica;
    case Region.MiddleEast:
      return middleEast;
    case Region.NorthAmerica:
      return northAmerica;
    case Region.APAC:
      return chinaAPAC;

    default:
      throw Error(`getNativeStarchData unknown region ${region}`);
  }
};
export default getNativeStarchData;
