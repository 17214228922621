export type EthonalData = {
  enzymeCost: number;
  lossStillageFermentation: number;
};

const ethonalData: EthonalData = {
  enzymeCost: 1,
  lossStillageFermentation: 0,
};

export default ethonalData;
