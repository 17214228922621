export type EndProducts = {
  Fiber: number;
  CSL: number;
  CGF: number;
  CGM: number;
  Germ: number;
  Starch: number;
};

const endProducts: EndProducts = {
  Fiber: 0.61,
  CSL: 0.48,
  CGF: 0.12,
  CGM: 0.08,
  Germ: 0.04,
  Starch: 0.12,
};

export default endProducts;
