// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default (url: string, data: Record<string, any>, onSubmitted?: (data?: any) => void): void => {
  const urlParams = new URLSearchParams();

  Object.keys(data).forEach(function test(key) {
    urlParams.append(key, data[key]);
  });

  fetch(url, {
    method: 'post',
    mode: 'no-cors',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: urlParams,
  })
    .then(() => {
      if (onSubmitted) {
        onSubmitted(data);
      }
    })
    .catch((err) => {
      console.log('Error=>', err);
      // do nothing for now DataDog later
    });
};
