import React, { ReactElement, useCallback } from 'react';
import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from 'recharts';
import { frontiaColors } from 'theme/colors';

export interface BreakDownEntry {
  name: string;
  value: number;
}

export interface BreakDownChartProps {
  id: string;
  chartData: BreakDownEntry[];
  formatLabel(value: number, decimals?: number): string;
}

const getColorForIndex = () => (index: number) => {
  const colors = [frontiaColors.grayBlue, frontiaColors.primaryGreen, '#2d0028'];
  switch (index) {
    case 0:
      return colors[0];
    case 1:
      return colors[1];
    case 2:
      return colors[2];
    default:
      return colors[Math.floor(Math.random() * colors.length)];
  }
};

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { cx, cy, midAngle, innerRadius, outerRadius, value }: any,
  formatLabel: (value: number, decimals?: number | undefined) => string
) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{ fontSize: '20px', fontWeight: 700, fontStyle: 'bold', fontFamily: 'Novozymes' }}
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {formatLabel(value)}
    </text>
  );
};

const BreakDownChart = ({ id, chartData, formatLabel }: BreakDownChartProps): ReactElement => {
  const getColor = useCallback((index) => getColorForIndex()(index), []);
  return (
    <ResponsiveContainer id={id} height={395} width={500}>
      <PieChart>
        <Pie
          data={chartData.filter((entry) => entry.value)}
          dataKey="value"
          nameKey="name"
          cx="50%"
          cy="50%"
          labelLine={false}
          label={(props) => renderCustomizedLabel(props, formatLabel)}
          isAnimationActive={false}
        >
          {chartData.map((entry, index) => (
            <Cell strokeWidth="0" key={`cell-${entry.name}`} fill={getColor(index)} />
          ))}
        </Pie>
        <Legend />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default BreakDownChart;
