// 1 year average pulled from
// https://www.ofx.com/en-au/forex-news/historical-exchange-rates/yearly-average-rates/
const AVG_1Y_EURO_TO_USD = 1;

const getEuroToUsd = async (): Promise<number> => {
  try {
    // eslint-disable-next-line no-undef
    const response = await fetch('https://api.exchangeratesapi.io/latest?symbols=USD');
    const body = await response.json();
    return body.rates.USD;
  } catch (error) {
    return AVG_1Y_EURO_TO_USD;
  }
};

export default getEuroToUsd;
