import { Dictionary } from 'types/Dictionary';
import { YesNo } from 'appState/AppStateTypes';
import { LessCornMillingScenario } from './LessCornMillingScenario';
import { CalculateInput } from './CalculationTypes';
import { MoreStarchScenario } from './MoreStarchScenario';
import { Region } from 'staticData/Region';
import plantCapacityData from './presetData/PlantCapacityInput';

export interface FinalCalculationSummary {
  benefitsStarch: Dictionary<number>;
  benefitsEnergy: Dictionary<number>;
  benefitsSales: Dictionary<number>;
  benefitsPayBack: Dictionary<number>;
  benefitsFlexibility: Dictionary<number>;
}

export default class FinalCalculation {
  static benefitsNetStarchCost(
    lessCornScenario: LessCornMillingScenario,
    moreStarchScenario: MoreStarchScenario,
    userInput: CalculateInput,
    runFullLessCorn: boolean
  ): Dictionary<number> {
    const benefits: Dictionary<number> = {};

    const daysNotFullCapacity = userInput.daysOperating - userInput.daysFullCapacity;
    if (runFullLessCorn) {
      benefits.lessCorn =
        (lessCornScenario.afterFrontia.corn.revenue - lessCornScenario.beforeFrontia.corn.revenue) *
        userInput.daysOperating;

      benefits.cornGlutenMeal =
        (lessCornScenario.afterFrontia.cgm.revenue - lessCornScenario.beforeFrontia.cgm.revenue) *
        userInput.daysOperating;

      benefits.cornGlutenFeed =
        (lessCornScenario.afterFrontia.cgf.revenue - lessCornScenario.beforeFrontia.cgf.revenue) *
        userInput.daysOperating;

      benefits.cornGerm =
        (lessCornScenario.afterFrontia.germ.revenue - lessCornScenario.beforeFrontia.germ.revenue) *
        userInput.daysOperating;

      benefits.cornSteepLiquor =
        (lessCornScenario.afterFrontia.csl.revenue - lessCornScenario.beforeFrontia.csl.revenue) *
        userInput.daysOperating;
    } else {
      benefits.lessCorn =
        (lessCornScenario.afterFrontia.corn.revenue - lessCornScenario.beforeFrontia.corn.revenue) *
        daysNotFullCapacity;

      benefits.cornGlutenMeal =
        (lessCornScenario.afterFrontia.cgm.revenue - lessCornScenario.beforeFrontia.cgm.revenue) * daysNotFullCapacity +
        (moreStarchScenario.afterFrontia.cgm.revenue - moreStarchScenario.beforeFrontia.cgm.revenue) *
          userInput.daysFullCapacity;

      benefits.cornGlutenFeed =
        (lessCornScenario.afterFrontia.cgf.revenue - lessCornScenario.beforeFrontia.cgf.revenue) * daysNotFullCapacity +
        (moreStarchScenario.afterFrontia.cgf.revenue - moreStarchScenario.beforeFrontia.cgf.revenue) *
          userInput.daysFullCapacity;

      benefits.cornGerm =
        (lessCornScenario.afterFrontia.germ.revenue - lessCornScenario.beforeFrontia.germ.revenue) *
          daysNotFullCapacity +
        (moreStarchScenario.afterFrontia.germ.revenue - moreStarchScenario.beforeFrontia.germ.revenue) *
          userInput.daysFullCapacity;

      benefits.cornSteepLiquor =
        (lessCornScenario.afterFrontia.csl.revenue - lessCornScenario.beforeFrontia.csl.revenue) * daysNotFullCapacity +
        (moreStarchScenario.afterFrontia.csl.revenue - moreStarchScenario.beforeFrontia.csl.revenue) *
          userInput.daysFullCapacity;
    }

    benefits.net =
      benefits.lessCorn +
      benefits.cornGlutenMeal +
      benefits.cornGlutenFeed +
      benefits.cornGerm +
      benefits.cornSteepLiquor;
    return benefits;
  }

  static benefitsEnergy(
    lessCornScenario: LessCornMillingScenario,
    moreStarchScenario: MoreStarchScenario,
    userInput: CalculateInput,
    backgroundEnergy: Dictionary<number>,
    runFullLessCorn: boolean
  ): Dictionary<number> {
    const benefits: Dictionary<number> = {};

    const daysNotFullCapacity = userInput.daysOperating - userInput.daysFullCapacity;

    if (runFullLessCorn) {
      benefits.lessFiberDryer =
        userInput.feedDryer === YesNo.yes
          ? (lessCornScenario.energySavings.fiber.beforeFrontia - lessCornScenario.energySavings.fiber.afterFrontia) *
            userInput.daysOperating
          : 0;

      benefits.lessGermDryer =
        (lessCornScenario.energySavings.germ.beforeFrontia - lessCornScenario.energySavings.germ.afterFrontia) *
        userInput.daysOperating;

      benefits.lessCgmDryer =
        (lessCornScenario.energySavings.gluten.beforeFrontia - lessCornScenario.energySavings.gluten.afterFrontia) *
        userInput.daysOperating;

      benefits.lessElectricity = lessCornScenario.electricitySavings * userInput.daysOperating;
    } else {
      benefits.lessFiberDryer =
        userInput.feedDryer === YesNo.yes
          ? (lessCornScenario.energySavings.fiber.beforeFrontia - lessCornScenario.energySavings.fiber.afterFrontia) *
              daysNotFullCapacity +
            (moreStarchScenario.energySavings.fiber.beforeFrontia -
              moreStarchScenario.energySavings.fiber.afterFrontia) *
              userInput.daysFullCapacity
          : 0;

      benefits.lessGermDryer =
        (lessCornScenario.energySavings.germ.beforeFrontia - lessCornScenario.energySavings.germ.afterFrontia) *
          daysNotFullCapacity +
        (moreStarchScenario.energySavings.germ.beforeFrontia - moreStarchScenario.energySavings.germ.afterFrontia) *
          userInput.daysFullCapacity;

      benefits.lessCgmDryer =
        (lessCornScenario.energySavings.gluten.beforeFrontia - lessCornScenario.energySavings.gluten.afterFrontia) *
          daysNotFullCapacity +
        (moreStarchScenario.energySavings.gluten.beforeFrontia - moreStarchScenario.energySavings.gluten.afterFrontia) *
          userInput.daysFullCapacity;

      benefits.lessElectricity =
        lessCornScenario.electricitySavings * daysNotFullCapacity +
        moreStarchScenario.electricitySavings * userInput.daysFullCapacity;
    }

    benefits.moreClsDried = -lessCornScenario.extraClsDrying.evaporationCost;
    benefits.lessEvaporator = userInput.feedDryer === YesNo.no ? backgroundEnergy.savings : 0;

    benefits.net =
      benefits.lessFiberDryer +
      benefits.lessGermDryer +
      benefits.lessCgmDryer +
      benefits.lessElectricity +
      benefits.moreClsDried +
      benefits.lessEvaporator;
    return benefits;
  }

  static benefitsHigherSales(
    userInput: CalculateInput,
    moreStarchScenario: MoreStarchScenario,
    runFullLessCorn: boolean,
    benefitsTranslated: number
  ): Dictionary<number> {
    const benefits: Dictionary<number> = {};

    if (runFullLessCorn) {
      benefits.ethonalSales = 0;
      benefits.starchSales = 0;
      benefits.net = benefits.ethonalSales + benefits.starchSales;
      return benefits;
    }

    benefits.ethonalSales =
      ((userInput.produceEthanol === YesNo.yes ? moreStarchScenario.moreStarch.yearlyBenefitEtoh : 0) *
        userInput.daysFullCapacity) /
      userInput.daysOperating;

    //benefits.starchSales =
    //    ((userInput.produceEthanol === YesNo.no ? moreStarchScenario.moreStarch.yearlyBenefitNativeStarch : 0) *
    //        userInput.daysFullCapacity) /
    //    userInput.daysOperating;

    benefits.starchSales =
      ((userInput.produceEthanol === YesNo.no ? moreStarchScenario.moreStarch.yearlyBenefitNativeStarch : 0) *
        userInput.daysFullCapacity) /
        userInput.daysOperating +
      benefitsTranslated;

    benefits.net = benefits.ethonalSales + benefits.starchSales;
    return benefits;
  }

  static benefitsFlexibility(userInput: CalculateInput, lessCornScenario: LessCornMillingScenario): Dictionary<number> {
    const benefits: Dictionary<number> = {};

    // 2022 changes

    // const daysRunningLessCorn = runFullLessCorn
    // ? userInput.daysOperating
    // : userInput.daysOperating - userInput.daysFullCapacity;
    // benefits.extraDays =
    // ((lessCornScenario.beforeFrontia.corn.production - lessCornScenario.afterFrontia.corn.production) *
    // daysRunningLessCorn) /
    // lessCornScenario.beforeFrontia.corn.production;
    benefits.extraDays =
      ((lessCornScenario.beforeFrontia.corn.production - lessCornScenario.afterFrontia.corn.production) *
        userInput.daysOperating) /
      lessCornScenario.beforeFrontia.corn.production;

    const plantCapacity = plantCapacityData(userInput);

    // benefits.extraDaysInUSD =
    //   userInput.region === Region.NorthAmerica ? benefits.extraDays * plantCapacity.millingRate * 39.28 * 2 * 0.5 : 0;
    // return benefits;

    benefits.extraDaysInUSD =
      userInput.region === Region.NorthAmerica ? benefits.extraDays * plantCapacity.millingRate * 39.28 * 2 : 0;

    return benefits;
  }

  static benefitsPayBack(
    benefitsStarch: Dictionary<number>,
    benefitsEnergy: Dictionary<number>,
    benefitsSales: Dictionary<number>,
    userInput: CalculateInput
  ): Dictionary<number> {
    const benefits: Dictionary<number> = {};
    const plantCapacity = plantCapacityData(userInput);
    benefits.capexEstimate = (780000 + 7 * plantCapacity.millingRate * 39.368) * 1.4;
    benefits.netValue =
      benefitsStarch.net +
      benefitsEnergy.net +
      benefitsSales.net -
      8 * 0.15 * userInput.daysOperating * plantCapacity.millingRate;
    benefits.payBackTimeMonths = (benefits.capexEstimate / benefits.netValue) * 12;
    return benefits;
  }

  static finalCalculation(
    lessCornScenario: LessCornMillingScenario,
    moreStarchScenario: MoreStarchScenario,
    userInput: CalculateInput,
    backgroundEnergy: Dictionary<number>
  ): FinalCalculationSummary {
    const runFullLessCorn =
      lessCornScenario.lessCornMilling.totalGrossBenefit > moreStarchScenario.moreStarch.totalGrossBenefit;

    const benefitsStarch = this.benefitsNetStarchCost(lessCornScenario, moreStarchScenario, userInput, runFullLessCorn);
    const benefitsEnergy = this.benefitsEnergy(
      lessCornScenario,
      moreStarchScenario,
      userInput,
      backgroundEnergy,
      runFullLessCorn
    );
    const benefitsFlexibility = this.benefitsFlexibility(userInput, lessCornScenario);
    const benefitsSales = this.benefitsHigherSales(
      userInput,
      moreStarchScenario,
      runFullLessCorn,
      benefitsFlexibility.extraDaysInUSD
    );

    const finalCalculation: FinalCalculationSummary = {
      benefitsStarch: benefitsStarch,
      benefitsEnergy: benefitsEnergy,
      benefitsSales: this.benefitsHigherSales(
        userInput,
        moreStarchScenario,
        runFullLessCorn,
        benefitsFlexibility.extraDaysInUSD
      ),
      benefitsFlexibility: benefitsFlexibility,
      benefitsPayBack: this.benefitsPayBack(benefitsStarch, benefitsEnergy, benefitsSales, userInput),
    };

    return finalCalculation;
  }
}
