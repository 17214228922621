import React, { ReactElement } from 'react';
import { BarChart, XAxis, LabelList, Bar, ResponsiveContainer } from 'recharts';
import { withTheme, makeStyles, useTheme } from '@material-ui/core';
import { numberToStringCompact } from '../../utils/NumberHelper';
import { frontiaColors } from 'theme/colors';

export type BarType = {
  dataKey: string;
  dataNameKey: string;
  dataHiddenKey?: string;
  highlighted?: boolean;
  value: number;
  title: string;
};

type WaterfallChartProps = {
  id?: string;
  bars: BarType[];
  height: number | string;
  width: number | string;
};

const useStyles = makeStyles((theme) => ({
  whiteFont: {
    fill: theme.palette.common.black,
    fontFamily: 'Novozymes',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  labels: {
    fill: theme.palette.common.white,
    fontFamily: 'Novozymes',
    fontSize: '24px',
    fontWeight: 'bold',
  },
  labelsBars: {
    fontFamily: 'Novozymes',
    fontSize: '24px',
  },
}));

const sortBarsByValue = (a: BarType, b: BarType) => {
  if (a.value > b.value) {
    return -1;
  }
  if (a.value < b.value) {
    return 1;
  }
  return 0;
};

function WaterfallChart({ id, bars, height, width }: WaterfallChartProps): ReactElement {
  const classes = useStyles();
  const theme = useTheme();
  const barsClone = [...bars];
  const nonTotalNonZeroBars = barsClone
    .filter((bar) => bar.dataKey !== 'total')
    .filter((bar) => !!Math.round(bar.value));
  nonTotalNonZeroBars.sort(sortBarsByValue);
  const totalBar = barsClone.find((bar) => bar.dataKey === 'total');
  const allBars = totalBar ? [...nonTotalNonZeroBars, totalBar] : nonTotalNonZeroBars;
  const data = allBars.reduce((acc, bar, index) => {
    const base = {
      [bar.dataKey]: bar.value,
      [bar.dataNameKey]: bar.title,
    };
    if (index === 0 || !bar.dataHiddenKey) {
      return { ...acc, ...base };
    }
    return {
      ...acc,
      ...base,
      [bar.dataHiddenKey]: allBars.slice(0, index).reduce((tot, prevBar) => tot + prevBar.value, 0),
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  }, {} as { [key: string]: any });

  return (
    <ResponsiveContainer id={id} height={height} width={width}>
      <BarChart data={[data]} margin={{ top: 20, bottom: 20 }}>
        <XAxis tick={false} domain={['auto', 'auto']} />
        {allBars
          .filter((bar) => data[bar.dataKey])
          .reduce((acc, barData, index) => {
            if (barData.dataHiddenKey) {
              acc.push(
                <Bar
                  key={barData.dataHiddenKey}
                  dataKey={barData.dataHiddenKey}
                  stackId={index}
                  fill="none"
                  isAnimationActive={false}
                >
                  <LabelList
                    dataKey={barData.dataNameKey}
                    position="bottom"
                    width="100px"
                    className={classes.labelsBars}
                  />
                </Bar>
              );
            }
            acc.push(
              <Bar
                key={barData.dataKey}
                stackId={index}
                isAnimationActive={false}
                dataKey={barData.dataKey}
                fill={barData.highlighted ? theme.palette.primary.dark : frontiaColors.grayBlue}
              >
                <LabelList
                  className={barData.highlighted ? classes.labels : classes.whiteFont}
                  dataKey={barData.dataKey}
                  formatter={(label: number): string => numberToStringCompact(parseFloat(label.toString()))}
                  position="inside"
                />
                {!barData.dataHiddenKey && (
                  <LabelList dataKey={barData.dataNameKey} position="bottom" className={classes.labelsBars} />
                )}
              </Bar>
            );

            return acc;
          }, [] as ReactElement[])}
      </BarChart>
    </ResponsiveContainer>
  );
}

export default withTheme(WaterfallChart);
